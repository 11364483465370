<template>
  <div style="display: flex">
    <!-- 侧边目录框 -->
    <el-aside
      :width="showAside ? '3%' : '20%'"
      :style="showAside ? 'transition: width .5s;' : ''"
    >
      <h1 v-if="!showAside">考试列表</h1>
      <div class="aside-input">
        <el-input
          v-model="searchInfo.name"
          placeholder="输入考试名称"
          prefix-icon="el-icon-search"
          v-if="!showAside"
          class="myinput"
          @input="getTeacherExamsLists"
        ></el-input>
      </div>
      <!-- 关闭展开二级菜单的按钮 -->
      <i class="el-icon-menu closeBtn1" @click="collapseStatus()"></i>
      <el-menu class="el-menu-vertical-demo main-menu" :collapse="showAside">
        <!-- 列表区域 -->
        <el-menu-item
          :index="index.toString()"
          v-for="(item, index) in subjectList"
          :key="index"
          @click="
            chooseChecked(item.examId, item.subjectName, index.toString())
          "
          v-if="item.subjectName"
        >
          <span slot="title" style="font-size: 12px"
            >{{ item.name }} + {{ item.subjectName }}</span
          >
        </el-menu-item>
      </el-menu>
    </el-aside>

    <!-- 考生监考模块 -->
    <el-container class="video-root-box" style="width: 80%; margin-top: 0">
      <el-main>
        <div class="box-header">
          <span style="width: 220px;">
            <el-input
                    v-show="showControl == 0"
                    v-model="videoCondition"
                    placeholder="输入考生姓名/身份证号"
                    prefix-icon="el-icon-search"
                    class="myInput"
                    @input="searchStudent()"
                    clearable
            ></el-input>
            <el-input
                    v-show="showControl == 1"
                    v-model="dataCondition"
                    placeholder="输入考生姓名/身份证号"
                    prefix-icon="el-icon-search"
                    class="myInput"
                    @input="searchStudentData()"
                    clearable
            ></el-input>
          </span>
          <span>考试名称：{{ examName }}</span>
          <span>场次：{{ subjectName }}</span>
          <span
            >科目：
            <el-select
              v-model="smallSubjectUuid"
              slot="prepend"
              placeholder="请选择"
              @change="changeSmallSubject"
            >
              <el-option
                v-for="(item, index) in selectSubjectLIst"
                :key="index"
                :value="item.smallSubjectUuid"
                :label="item.smallSubjectName"
              ></el-option>
            </el-select>
          </span>

          <span v-show="showControl == 0">
            <el-button type="primary" @click="handleControl"
              >数据监控</el-button
            >
          </span>
          <span v-show="showControl == 1">
            <el-button type="primary" @click="handleVideo">视频监控</el-button>
          </span>
          <!--<span>
            <el-button type="primary" @click="msgShow(0)"><i class="el-icon-chat-dot-round" style="margin-right: 5px"></i>全体通知</el-button>
          </span>-->
        </div>

        <el-container class="container-box">
          <!-- 视频监控 -->
          <el-main
            v-show="showControl == 0"
            v-loading="topLoading"
            :element-loading-text="topText"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 0.8)"
          >
            <div class="video-user-box" v-if="studentList.length > 0">
              <div
                v-for="(item, index) in studentList"
                :key="index"
                :class="`${'video-size' + videoSize} ${
                  item.is_check == 0 ? 'user-box' : 'user-boxerror'
                }`"
                :ref="item.zkzNum"
              >
                <div class="user-check_close" v-if="item.is_check == 1">
                  <i
                    @click="hideStudentDialogShow(item.zkzNum)"
                    class="el-icon-close"
                  ></i>
                </div>
                <div
                  class="user-box1"
                  v-for="(itemVideo, indexVideo) in userVideoList"
                  :key="indexVideo"
                  :class="showVideo ? '' : 'showVideo'"
                >
                  <img
                    class="thussat_logo"
                    src="../../../assets/image/logo.svg"
                    alt=""
                  />
                  <!-- <div class="status_icon">
                    <i
                      v-if="item.testStatus == 0"
                      class="el-icon-circle-close"
                      style="font-size: 25px; color: red"
                      @click="testStatusChange(item)"
                    ></i>
                    <i
                      v-else
                      class="el-icon-circle-check"
                      style="font-size: 25px; color: green"
                    ></i>
                  </div> -->
                  <div
                    v-if="item.faceStatus == '2' && itemVideo === 'front'"
                    class="isFacePass"
                  >
                    <div>未通过人脸识别</div>
                  </div>

                  <video
                    :ref="
                      'video_' +
                      subjectUuid +
                      '_' +
                      item.zkzNum +
                      '_' +
                      itemVideo
                    "
                    :key="
                      'video_' +
                      subjectUuid +
                      '_' +
                      item.zkzNum +
                      '_' +
                      itemVideo
                    "
                    autoplay
                    playsinline
                    v-show="showVideoModel(itemVideo, item)"
                  ></video>
                  <!-- <vue-drag-resize :isResizable="false" :aspectRatio="false" :w="imgwidth" :h="imgheight">
                                  <div class="monitor_box" v-show="isshowMe == true">
                                    <video :ref="'monitor_video_' +subjectUuid +'_' +item.zkzNum +'_'+itemVideo" autoplay playsinline></video>
                                  </div>
                                </vue-drag-resize>-->
                  <div
                    class="video_action"
                    v-if="
                      item[subjectUuid + '_' + item.zkzNum + '_' + itemVideo]
                        .is_join == 1
                    "
                  >
                    <i
                      class="el-icon-chat-dot-round"
                      @click="msgShow(1, item, itemVideo)"
                      v-if="!showVideo && itemVideo!='screen'"
                    ></i>
                    <template
                      v-if="
                        item[subjectUuid + '_' + item.zkzNum + '_' + itemVideo]
                          .is_video == 1 && itemVideo!='screen'
                      "
                    >
                      <i
                        :class="
                          item[
                            subjectUuid + '_' + item.zkzNum + '_' + itemVideo
                          ].is_audio == 1
                            ? 'yd004laba-3'
                            : 'yd004laba-4'
                        "
                        class="ydfont"
                        @click="
                          subscribeAudio(
                            subjectUuid + '_' + item.zkzNum + '_' + itemVideo,
                            item
                          )
                        "
                      ></i>
                      <i
                        :class="
                          item[
                            subjectUuid + '_' + item.zkzNum + '_' + itemVideo
                          ].is_call == 1
                            ? 'ydmaikefengxianxing'
                            : 'ydmaikefeng-jingyin'
                        "
                        @click="
                          callOneStudent(
                            subjectUuid + '_' + item.zkzNum + '_' + itemVideo,
                            item
                          )
                        "
                        class="ydfont"
                      ></i>
                    </template>
                    <i :class="showVideo ? 'ydicon--1' : 'ydicon--'"
                            class="ydfont"
                            @click="
                          showTopVideo(
                            subjectUuid + '_' + item.zkzNum + '_' + itemVideo
                          )
                        "
                       v-if="
                        item[subjectUuid + '_' + item.zkzNum + '_' + itemVideo]
                          .is_video == 1
                      "
                    ></i>
                  </div>
                  <div
                    class="video_loading"
                    v-show="!showVideo"
                    :element-loading-text="
                      item[subjectUuid + '_' + item.zkzNum + '_' + itemVideo]
                        .is_join == 1
                        ? '加载视频中'
                        : '未加入监控'
                    "
                    element-loading-spinner="el-icon-loading"
                    v-loading="
                      item[subjectUuid + '_' + item.zkzNum + '_' + itemVideo]
                        .is_video == 0
                    "
                  ></div>
                </div>
                <div class="user-info flex">
                  <div class="user_text">
                    <span>证件号：</span>
                    <span v-text="item.zkzNum"></span>
                    <span>考生姓名：</span>
                    <span v-text="item.name"></span>
                  </div>
                  <div
                    class="user_text"
                    v-if="isChangeStatus == 0"
                    style="font-weight: 600"
                  ></div>
                  <div v-else class="user_text" style="font-weight: 600">
                    <div class="text_btn1">
                      <el-button @click="toErrorList(item.zkzNum, item.name)">
                        查看预警
                      </el-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 分页器 -->
            <el-pagination
              v-if="total && total > 0"
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-sizes="[3, 6, 9, 12]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              :page-count="totalPage"
            >
            </el-pagination>
            <div class="no-data" v-if="studentList.length == 0">暂无考生</div>
            <div
              v-show="top_video"
              class="top_video"
              :class="showVideo ? '' : 'showVideo'"
            >
              <video
                :id="'top_video_' + userId"
                :key="'top_video_' + userId"
                autoplay
                playsinline
              ></video>
              <div class="video_action" v-if="student[userId].is_join == 1">
                <i
                  :class="
                    student[userId].is_audio == 1
                      ? 'yd004laba-3'
                      : 'yd004laba-4'
                  "
                  class="ydfont"
                  @click="subscribeAudio(student.index, student.key, userId)"
                ></i>
                <i
                  :class="
                    student[userId].is_call == 1
                      ? 'ydmaikefengxianxing'
                      : 'ydmaikefeng-jingyin'
                  "
                  class="ydfont"
                  @click="callOneStudent(student.index, student.key, userId, 2)"
                ></i>
                <i
                  class="ydfont ydicon--1"
                  @click="hideTopVideo(student.index, userId)"
                ></i>
              </div>
            </div>
          </el-main>

          <!--  数据监控 -->
          <el-main v-show="showControl == 1" v-loading="topLoading">
            <el-card class="box-card mm">
              <div>
                <el-button
                  size="small"
                  type="primary"
                  icon="el-icon-refresh"
                  @click="refreshBtn"
                  >刷新</el-button
                >
              </div>
              <div>
                <el-table
                  class="data_tt"
                  :data="[generalControl]"
                  border
                  style="width: 100%"
                >
                  <el-table-column
                    prop="notEnterNum"
                    label="未进入监控人数"
                    align="center"
                    min-width="120"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="notStartExamNum"
                    label="未开考人数"
                    align="center"
                    min-width="120"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="notFaceNum"
                    label="未人脸识别人数"
                    align="center"
                    min-width="120"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="notSubjectiveNum"
                    label="未提交图片人数"
                    align="center"
                    min-width="120"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="notHandPaperNum"
                    label="未交卷人数"
                    align="center"
                    min-width="120"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="notTestNum"
                    label="未调试人数"
                    align="center"
                    min-width="120"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="lackExamStatus"
                    label="缺考人数"
                    align="center"
                    min-width="120"
                  >
                  </el-table-column>
                </el-table>
              </div>

              <div class="text item">
                <el-table
                  :data="dataControlList"
                  style="width: 100%"
                  height="auto"
                  @filter-change="filterChange"
                  @cell-mouse-enter="cellMouseEnter"
                  stripe
                >
                  <el-table-column
                    class="student_name"
                    align="center"
                    prop="name"
                    label="考生姓名"
                  >
                    <template slot-scope="scope">
                      <span
                        class="student_name"
                        @click="
                          showTopVideo(
                            scope.$index,
                            subjectUuid + '_' + scope.row.zkzNum + '_back'
                          )
                        "
                      >
                        {{ scope.row.name }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    prop="zkzNum"
                    label="准考证号"
                    min-width="120"
                  ></el-table-column>
                  <el-table-column
                    align="center"
                    label="监考人"
                    min-width="120"
                  >
                    <template slot-scope="scope">
                      <el-tooltip
                        effect="dark"
                        placement="top"
                        :content="scope.row.teacherName"
                        :disabled="!scope.row.teacherName"
                      >
                        <!-- <span>{{
                          scope.row.teacherName.indexOf(",") == -1
                        }}</span> -->
                        <span v-if="scope.row.teacherName.indexOf(',') != -1">
                          {{ scope.row.teacherName.split(",")[0] }}
                        </span>
                        <span v-else>
                          {{ scope.row.teacherName }}
                        </span>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    label="监考人手机号"
                    min-width="120"
                  >
                    <template slot-scope="scope">
                      <el-tooltip
                        effect="dark"
                        placement="top"
                        :content="scope.row.teacherPhone"
                        :disabled="!scope.row.teacherPhone"
                      >
                        <span v-if="scope.row.teacherPhone.indexOf(',') != -1">
                          {{ scope.row.teacherPhone.split(",")[0] }}
                        </span>
                        <span v-else>
                          {{ scope.row.teacherPhone }}
                        </span>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    prop="faceStatus;"
                    label="人脸识别状态"
                    min-width="140"
                    :filter-multiple="false"
                    :filters="[
                      { text: '未识别', value: '0' },
                      { text: '已识别', value: '1' },
                      { text: '识别未通过', value: '2' },
                    ]"
                    filter-placement="bottom-end"
                    column-key="faceStatus"
                  >
                    <template slot-scope="scope">
                      <span
                        v-if="scope.row.faceStatus === 1"
                        style="color: #67c23a"
                        >已识别</span
                      >
                      <span
                        v-else-if="scope.row.faceStatus === 0"
                        style="color: #f56c6c"
                        >未识别</span
                      >
                      <span
                        v-else-if="scope.row.faceStatus === 2"
                        style="color: #f56c6c"
                        >识别未通过</span
                      >
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    prop="monitorStatus;"
                    label="在线监控数"
                    min-width="120"
                    :filter-multiple="false"
                    :filters="[
                      { text: '0', value: '0' },
                      { text: '1', value: '1' },
                      { text: '2', value: '2' },
                    ]"
                    filter-placement="bottom-end"
                    column-key="monitorStatus"
                  >
                    <template slot-scope="scope">
                      <span
                        v-if="scope.row.monitorStatus === 0"
                        style="color: #f56c6c"
                        >0</span
                      >
                      <span
                        v-else-if="scope.row.monitorStatus === 1"
                        style="color: #67c23a"
                        >1</span
                      >
                      <span
                        v-else-if="scope.row.monitorStatus === 2"
                        style="color: #67c23a"
                        >2</span
                      >
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    prop="startExamStatus;"
                    label="开考状态"
                    min-width="100"
                    :filter-multiple="false"
                    :filters="[
                      { text: '未开考', value: '0' },
                      { text: '已开考', value: '1' },
                    ]"
                    filter-placement="bottom-end"
                    column-key="startExamStatus"
                  >
                    <template slot-scope="scope">
                      <span
                        v-if="scope.row.startExamStatus === 1"
                        style="color: #67c23a"
                        >已开考</span
                      >
                      <span
                        v-else-if="scope.row.startExamStatus === 0"
                        style="color: #f56c6c"
                        >未开考</span
                      >
                    </template>
                  </el-table-column>

                  <el-table-column
                    align="center"
                    prop="testStatus"
                    label="调试状态"
                    min-width="100"
                    :filter-multiple="false"
                    :filters="[
                      { text: '未调试', value: '0' },
                      { text: '已调试', value: '1' },
                    ]"
                    filter-placement="bottom-end"
                    column-key="testStatus"
                  >
                    <template slot-scope="scope">
                      <span
                        v-if="scope.row.testStatus === 1"
                        style="color: #67c23a"
                        >已调试</span
                      >
                      <span
                        v-else-if="scope.row.testStatus === 0"
                        style="color: #f56c6c"
                        >未调试</span
                      >
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    prop="pictureStatus;"
                    label="图片提交状态"
                    min-width="120"
                    :filter-multiple="false"
                    :filters="[
                      { text: '未提交', value: '0' },
                      { text: '已提交', value: '1' },
                    ]"
                    filter-placement="bottom-end"
                    column-key="pictureStatus"
                  >
                    <template slot-scope="scope">
                      <span
                        v-if="scope.row.pictureStatus === 1"
                        style="color: #67c23a"
                        >已提交</span
                      >
                      <span
                        v-else-if="scope.row.pictureStatus === 0"
                        style="color: #f56c6c"
                        >未提交</span
                      >
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    prop="handPaperStatus;"
                    label="交卷状态"
                    min-width="100"
                    :filter-multiple="false"
                    :filters="[
                      { text: '未交卷', value: '0' },
                      { text: '已交卷', value: '1' },
                    ]"
                    filter-placement="bottom-end"
                    column-key="handPaperStatus"
                  >
                    <template slot-scope="scope">
                      <span
                        v-if="scope.row.handPaperStatus === 1"
                        style="color: #67c23a"
                        >已交卷</span
                      >
                      <span
                        v-else-if="scope.row.handPaperStatus === 0"
                        style="color: #f56c6c"
                        >未交卷</span
                      >
                    </template>
                  </el-table-column>
                  <!-- 是否缺考 -->
                  <el-table-column
                    align="center"
                    prop="lackExamStatus;"
                    label="是否缺考"
                    min-width="100"
                    :filter-multiple="false"
                    :filters="[
                      { text: '未缺考', value: '0' },
                      { text: '缺考', value: '1' },
                    ]"
                    filter-placement="bottom-end"
                    column-key="lackExamStatus"
                  >
                    <template slot-scope="scope">
                      <span
                        v-if="scope.row.lackExamStatus === 1"
                        style="color: #f56c6c"
                        >缺考</span
                      >
                      <span
                        v-else-if="scope.row.lackExamStatus === 0"
                        style="color: #67c23a"
                        >未缺考</span
                      >
                    </template>
                  </el-table-column>
                  <!-- 考试结束时间 -->
                  <el-table-column
                    align="center"
                    label="考试结束时间"
                    min-width="120"
                  >
                    <template slot-scope="scope">
                      <span
                        v-if="scope.row.additionalStatus === 1"
                        style="color: #f56c6c"
                        >{{ scope.row.finalEndTime }}</span
                      >
                      <span
                        v-else-if="scope.row.additionalStatus === 0"
                        style="color: #67c23a"
                      >
                        {{ scope.row.finalEndTime }}
                      </span>
                    </template>
                  </el-table-column>
                </el-table>

                <!-- 分页器 -->
                <el-pagination
                  v-if="totalNum"
                  background
                  @size-change="handleDataSize"
                  @current-change="handleDataCurrente"
                  :current-page="queryParamsData.pageNum"
                  :page-sizes="[5, 10, 15, 20]"
                  :page-size="queryParamsData.pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="totalNum"
                >
                </el-pagination>
              </div>
            </el-card>
          </el-main>
        </el-container>
      </el-main>

      <div class="checkBg" v-if="is_check"></div>
      <!-- 居家考消息 弹框 -->
      <msgDialog
        :dialogVisibleFu="dialogVisibleFu"
        :allSend="all_send"
        :userKey="userKey"
        @closeDialog="closeDialog"
      />
      <!-- 单个预警截图 -->
      <el-dialog
        width="750px"
        title="预警截图"
        :visible.sync="dialogVisibleError"
        @open="openErroImgDialog"
        @close="closeErrorImgDialog"
      >
        <el-table border :data="errorImagesList">
          <el-table-column
            prop="name"
            label="姓名"
            width="100px"
          ></el-table-column>
          <el-table-column prop="zkzNum" label="准考证"></el-table-column>
          <el-table-column prop="type" label="预警类型">
            <template slot-scope="scope">
              <el-tag
                v-for="(item, index) in typeFormat(scope.row)"
                :key="index"
                type="success"
                style="margin: 0 5px 5px 0"
                >{{ item }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="imagesTime" label="截图时间"></el-table-column>
          <el-table-column prop="imageUrl" label="预警截图">
            <template slot-scope="scope">
              <el-image
                style="width: 100px; height: 100px"
                :src="scope.row.imageUrl"
                :fit="fit"
                :preview-src-list="[scope.row.imageUrl]"
              >
              </el-image>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页器 -->
        <el-pagination
          v-if="errorimgTotal"
          background
          @size-change="handleErrImgSize"
          @current-change="handleErrImgCurrente"
          :current-page="queryParamsError.pageNum"
          :page-sizes="[4, 6, 8, 10]"
          :page-size="queryParamsError.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="errorimgTotal"
        >
        </el-pagination>
      </el-dialog>

      <!-- 查看所有预警table -->
      <el-dialog
        width="720px"
        :title="'科目: ' + subjectName"
        :visible.sync="dialogTableVisible"
        @open="openImgDialog"
        @close="closeImgDialog"
      >
        <el-form
          :model="queryParams"
          ref="queryForm"
          :inline="true"
          label-width="100px"
        >
          <el-form-item label="准考证号：" prop="zkzNum">
            <el-input
              v-model="queryParams.zkzNum"
              placeholder="请输入准考证号"
              clearable
              size="small"
            />
          </el-form-item>
          <el-form-item label="预警类型" prop="type">
            <el-select
              v-model="queryParams.type"
              placeholder="请选择预警类型"
              size="small"
              clearable
            >
              <el-option
                v-for="(item, index) in typeList"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="起止时间：">
            <el-date-picker
              v-model="valuePicker"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd hh:mm:ss"
              size="small"
              clearable
              @change="changePicker"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="queryHandle"
              >搜索
            </el-button>
            <el-button
              type="primary"
              icon="el-icon-refresh"
              size="mini"
              @click="resetHandle"
              >重置
            </el-button>
          </el-form-item>
        </el-form>
        <el-table :data="imagesList">
          <el-table-column prop="zkzNum" label="准考证"></el-table-column>
          <el-table-column prop="type" label="预警类型">
            <template slot-scope="scope">
              <el-tag
                v-for="(item, index) in typeFormat(scope.row)"
                :key="index"
                type="success"
                style="margin: 0 5px 5px 0"
                >{{ item }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="imagesTime" label="截图时间"></el-table-column>
          <el-table-column prop="imageUrl" label="预警截图">
            <template slot-scope="scope">
              <el-image
                style="width: 100px; height: 100px"
                :src="scope.row.imageUrl"
                :preview-src-list="[scope.row.imageUrl]"
              >
              </el-image>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页器 -->
        <el-pagination
          v-if="imgTotal"
          background
          @size-change="handleImgSizeChange"
          @current-change="handleImgCurrentChange"
          :current-page="queryParams.pageNum"
          :page-sizes="[4, 6, 8, 10]"
          :page-size="queryParams.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="imgTotal"
        >
        </el-pagination>
      </el-dialog>

      <!--学生视频异常列表-->
      <el-drawer
        title="异常视频"
        :visible.sync="drawerShow"
        direction="rtl"
        :modal="false"
        :wrapperClosable="false"
        :before-close="handleDrawClose"
      >
        <div class="drawer-list">
          <div class="drawer-list-item">
            <div class="text">isejfiaviwsvws</div>
            <el-button type="text">查看</el-button>
          </div>
          <div class="drawer-list-item">
            <div class="text">isejfiaviwsvws</div>
            <el-button type="text">查看</el-button>
          </div>
          <div class="drawer-list-item">
            <div class="text">isejfiaviwsvws</div>
            <el-button type="text">查看</el-button>
          </div>
          <div class="drawer-list-item">
            <div class="text">isejfiaviwsvws</div>
            <el-button type="text">查看</el-button>
          </div>
        </div>
      </el-drawer>
    </el-container>
  </div>
</template>
<script>
import Header from "c/index/Header";
import { videoMixin } from "mixins/admin/video";
import msgDialog from "c/index/examination/msgDialog";
import imgListDialog from "c/index/examination/imgListDialog";

export default {
  name: "examVideoList",
  mixins: [videoMixin],
  components: {
    Header,
    msgDialog,
    imgListDialog,
  },
  data() {
    return {};
  },
  mounted() {},
  created() {},

  methods: {
    // showTopVideo(index, userid) {
    //   // console.log(scope, "scope");
    //   console.log(index, "index");
    //   console.log(userid, "userid");
    // },
    //点击查看视频
    //获取监控科目列表
    // async getSubjectList(index) {
    //   const { data: res } = await getAllteacherSubs(this.examlistInfo);
    //   if (res.code != 200) {
    //     this.$message({
    //       message: res.message,
    //       type: "error",
    //     });
    //     return;
    //   }
    //   if (res.code == 200) {
    //     console.log("监控科目列表,,,,,", res);
    //     let sublist = [];
    //     let arr = [];
    //     sublist = res.data.teacherExamDto.examSubjects ? res.data.teacherExamDto.examSubjects:[];
    //     console.log(sublist, "kemuliebiao 22222");
    //     if (sublist.length != 0) {
    //       this.examInfoList.forEach((item) => {
    //         console.log(item, "this。examInfoList");
    //         if (item.uuid === this.examlistInfo.examId) {
    //           this.examName = item.name;
    //           console.log("yes  yes", item.name);
    //           for (let i = 0; i < sublist.length; i++) {
    //             sublist[i].listName = item.name + "+" + sublist[i].subjectName;
    //             sublist[i].examName = item.name;
    //             arr.push(sublist[i]);
    //             console.log(arr, "。。。。。。。");
    //           }
    //         }
    //       });
    //       this.subjectList = arr;
    //       console.log(this.subjectList, "科目列表列表表表");
    //     }
    //     if (index) {
    //       //判断是否点击选择之后查看监控
    //       this.subjectName =
    //         res.data.teacherExamDto.examSubjects[index].subjectName;
    //       this.examId = res.data.teacherExamDto.examSubjects[index].examId;
    //       this.examUuid = res.data.teacherExamDto.examSubjects[index].examId;
    //       this.subjectId =
    //         res.data.teacherExamDto.examSubjects[index].subjectId;
    //       this.subjectUuid =
    //         res.data.teacherExamDto.examSubjects[index].subjectUuid;
    //     } else {
    //       //在没有点击情况下默认时第一个
    //       this.subjectName =
    //         res.data.teacherExamDto.examSubjects[0].subjectName;
    //       this.examId = res.data.teacherExamDto.examSubjects[0].examId;
    //       this.examUuid = res.data.teacherExamDto.examSubjects[0].examId;
    //       this.subjectId = res.data.teacherExamDto.examSubjects[0].subjectId;
    //       this.subjectUuid =
    //         res.data.teacherExamDto.examSubjects[0].subjectUuid;
    //     }
    //   }
    //   console.log(this.subjectUuid, "返回页面的subjectUuid");
    //   this.getList();
    // },
    //获取当前监控组的所有学生
    // async getList() {
    //   this.getSelectSubject(); //获取分科目
    //   this.topLoading = true;
    //   this.menuLoading = true;
    //   this.topText = "数据加载中";
    //   // console.log(
    //   // this.subjectUuid,
    //   // this.examUuid,
    //   // this.teacherId,
    //   // this.subjectId,
    //   // "监控组所有学生....."
    //   // );
    //   let { data } = await getStudentsAlic2(this.subjectId, this.examUuid);
    //   // console.log(data, "监控组学生数据...........");
    //   this.topLoading = false;
    //   this.menuLoading = false;
    //   this.topText = "";
    //   if (data && data.length > 0) {
    //     // console.log("nihao", data[0])
    //     let str = data[0];
    //     let arr = [];
    //     for (let i in str) {
    //       arr.push(i);
    //     }
    //     // console.log(data[0][arr[0]],"返回数组")
    //     let list = data;
    //     this.list = list;
    //     this.videoSize = parseInt(data[0].videoSize);
    //     this.createRtc();
    //   }
    // },
  },
  // 销毁监听事件
  destroyed() {},
};
</script>

<style scoped lang="scss">
@import "a/scss/page";
@import "a/scss/indexList";
@import "a/scss/menuitem";
@import "a/scss/contentheader";
@import "a/scss/table";
@import "a/scss/page";
@import "a/scss/btn";
@import "a/scss/dialog";
.mm div {
  margin-bottom: 20px;
}
.el-button + .el-button {
  margin-left: 0px;
}
.el-button {
  border-color: #fff !important;
}
.el-table td,
.el-table th {
  /* padding: 12px 0; */
  min-width: 0 !important;
  box-sizing: border-box !important;
  text-overflow: ellipsis !important;
  vertical-align: middle !important;
  position: relative !important;
  text-align: left !important;
}
.table th {
  padding: 0 !important;
}
.el-container {
  .el-main {
    padding: 1.5%;
    width: 100%;
    // overflow: hidden;
    .el-container {
      width: 100%;
      margin-top: 1%;
      display: flex;
      flex-flow: row;

      .el-aside {
        width: 300px;
        height: 100%;
        display: flex;
        flex-flow: column;
        overflow: hidden;
        background-color: #fff;
        border-radius: 6px;
        .message-box {
          p {
            text-align: center;
            line-height: 30px;
            height: 30px;
            color: #aaa;
          }
        }
        .aside-header {
          width: 100%;
          // height: 12.5%;
          text-align: center;
          padding: 0 5%;
          > h2 {
            // height: 50%;
            padding: 5% 0;
            // height: 55px;
            // line-height: 55px;
            text-align: center;
            font-size: 18px;
            font-family: "Source Han Sans CN";
            font-weight: 300;
            color: #333333;
          }

          > div {
            height: 40px;
            ::v-deep .el-input {
              position: relative;
              display: block;

              .el-input__inner {
                height: 40px;
                line-height: 40px;
                color: #cccccc;
                background-color: #fafafa;
                border-radius: 6px;
                border: none;
                font-size: 12px;
                font-family: "Source Han Sans CN";
                font-weight: 300;
              }
            }
          }
          clear: both;
        }

        .menu-box {
          flex: 1;
          overflow: auto;
          border-top: 1px solid #f5f5f5;
          margin-top: 6%;
          padding: 6% 0 0 0;
          display: flex;
          flex-flow: column;

          ::v-deep .el-radio-group {
            height: 6%;
            line-height: 6%;
            margin-left: 50px;
            .el-radio-button__inner {
              width: 100px;
              padding: 10% 0;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #333333;

              &:hover {
                color: #56327a;
              }
            }
            .el-radio-button.is-active {
              .el-radio-button__inner {
                color: #fff;
                background-color: #56327a;
                border-color: #56327a;
              }
            }

            .el-checkbox-group {
              margin-left: 50px;
              border-top: 1px solid #f5f5f5;
              padding-top: 18px;
            }
            ::v-deep .el-badge__content.is-fixed {
              position: absolute;
              top: 12px;
              right: 36px;
              border: none;
            }
          }

          .el-menu {
            flex: 1;
            margin: 20px 0 0 0;
            overflow: auto;
            .el-menu-item {
              height: inherit;
              line-height: inherit;
              margin: 5px;
              font-size: 12px;
              font-family: "Source Han Sans CN";
              font-weight: 300;
              color: rgba(0, 0, 0, 0.7);
              padding: 0 10px 0 20px;

              // 单行文本溢出隐藏显示省略号
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              &:first-child {
                margin-top: 0;
              }

              .item-badge {
                width: 100%;
              }

              .el-row {
                height: 100%;
                padding: 0;

                .el-col-8 {
                  height: inherit;
                  div {
                    float: left;
                    height: 40px;
                    line-height: 40px;
                    padding-left: 4px;
                    margin-top: -2px;
                    .el-link {
                      height: 100%;
                      font-size: 14px;
                      line-height: 20px;
                      font-weight: normal;
                      opacity: 0.7;
                    }
                    &:first-child {
                      .el-link {
                        color: #62affe;
                      }
                    }
                    &:last-child {
                      .el-link {
                        color: #f56c6c;
                      }
                    }
                  }
                }
              }
              ::v-deep .el-badge__content.is-fixed.is-dot {
                position: absolute;
                right: 10px;
                top: 10px;
              }
            }

            .el-menu-item:hover,
            .el-menu-item:hover .el-link,
            .is-active,
            .is-active .el-link {
              background-color: #56327a;
              .el-col-16 {
                color: rgba(255, 255, 255, 0.85) !important;
              }
            }
          }
        }
      }

      .el-main {
        flex: 1;
        padding: 0 0 0 1%;
        display: flex;
        flex-flow: column;

        .video-user-box {
          flex: 1;
          overflow: auto;
          position: relative;

          .user-box {
            &.video-size1 {
              > div {
                width: 100%;
              }
            }

            /*width: 33%;*/
            width: 49%;
            height: 32.8%;
            display: inline-block;
            margin: 0 0.5% 0 0;
            background: #fff;
            border-radius: 6px;
            padding: 0;

            &:nth-child(3n) {
              margin-right: 0;
            }

            > div {
              /*width: 49%;*/
              width: 32%;
              height: 80%;
              display: inline-block;
              position: relative;
              margin-right: 1%;
              border-radius: 6px;
              float: left;

              &.user-info {
                width: 100%;
                font-size: 12px;
                padding: 0 10px;
                margin-right: 0;
                margin-top: 15px;
                font-family: "Source Han Sans CN";
                font-weight: 400;
                color: #333333;
                max-height: 30px;

                .user_text {
                  // width: 50%;
                  position: relative;
                  display: inline-block;
                  &:first-child {
                    width: 65%;
                    text-align: left;
                    > span:nth-child(2) {
                      margin-right: 5px;
                    }
                  }
                  &:nth-child(2) {
                    width: 35%;
                    text-align: right;
                    > div {
                      color: #52a1e4;
                      display: inline-block;
                      cursor: pointer;
                      margin-right: 5px;
                    }
                    > span {
                      height: 10px;
                      width: 46px;
                      display: inline-block;
                      position: relative;
                    }
                  }
                }
              }
              .text_btn2 {
                color: #52a1e4;
                // height: 20px;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translate(0, -50%);
                cursor: pointer;
              }

              &.showVideo {
                > video {
                  /*border: 1px dotted #ccc;*/
                }
                ::v-deep .el-loading-mask {
                  border: 1px dotted #ccc;
                }
              }

              > video {
                width: 100%;
                height: 100%;
                border: 0;
                border-radius: 6px;
                position: absolute;
                top: 0;
                z-index: 2;
                left: 0;
              }

              .video_action {
                position: absolute;
                top: 8px;
                right: 2px;
                z-index: 3;

                .ydfont,
                .el-icon-chat-dot-round,
                .el-icon-top {
                  padding: 2px;
                  margin-right: 2px;
                  display: inline-block;
                  width: 28px;
                  height: 28px;
                  line-height: 28px;
                  text-align: center;
                  background: #ffffff;
                  border-radius: 50%;
                  color: #563279;
                  font-size: 20px;
                  font-weight: bold;
                }
              }

              .video_loading {
                position: absolute !important;
                top: 1px;
                height: 98%;
                z-index: 1;
                width: 98%;
                left: 1px;
                background-color: #ccc;
                width: 100%;
                height: 100%;
                border-radius: 6px;
              }
            }
          }

          .user-boxerror {
            &.video-size1 {
              > div {
                width: 100%;
              }
            }

            position: absolute;
            width: 45%;
            height: 650px;
            top: 0;
            z-index: 100;
            display: inline-block;
            background: #fff;
            border-radius: 6px;
            padding: 40px 20px 0 20px;
            left: 20%;
            &:nth-child(2n) {
              margin-right: 0;
              margin-left: 0.5%;
            }

            > div {
              &.user-check_close {
                width: 28px;
                height: 28px;
                position: absolute;
                right: -3px;
                top: 0;
                color: #56327a;
                font-size: 28px;
                z-index: 9;
                cursor: pointer;
              }

              width: 49%;
              height: 80%;
              display: inline-block;
              position: relative;
              margin-right: 1%;
              border-radius: 6px;
              float: left;

              &.user-info {
                width: 100%;
                font-size: 12px;
                padding: 0 10px;
                margin-right: 0;
                margin-top: 15px;
                font-family: "Source Han Sans CN";
                font-weight: 400;
                color: #333333;
                max-height: 30px;

                .user_text {
                  width: 50%;
                  position: relative;
                  display: inline-block;
                  &:first-child {
                    text-align: left;
                  }
                  &:nth-child(2) {
                    text-align: right;
                    > div {
                      color: #52a1e4;
                      display: inline-block;
                      cursor: pointer;
                      margin-right: 5px;
                    }
                    > span {
                      height: 10px;
                      width: 40px;
                      display: inline-block;
                      position: relative;
                    }
                  }
                }
              }
              .text_btn2 {
                color: #52a1e4;
                // height: 20px;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translate(0, -50%);
                cursor: pointer;
              }

              &.showVideo {
                > video {
                  border: 1px dotted #ccc;
                }
              }

              > video {
                width: 100%;
                height: 100%;
                border: 0;
                border-radius: 6px;
                position: absolute;
                top: 0;
                z-index: 2;
                left: 0;
              }

              .video_action {
                position: absolute;
                top: 8px;
                right: 2px;
                z-index: 3;

                .ydfont,
                .el-icon-chat-dot-round,
                .el-icon-top {
                  padding: 2px;
                  margin-right: 2px;
                  display: inline-block;
                  width: 28px;
                  height: 28px;
                  line-height: 28px;
                  text-align: center;
                  background: #ffffff;
                  border-radius: 50%;
                  color: #563279;
                  font-size: 20px;
                  font-weight: bold;
                }
              }

              .video_loading {
                position: absolute !important;
                top: 1px;
                height: 98%;
                z-index: 1;
                width: 98%;
                left: 1px;
                background-color: #ccc;
                width: 100%;
                height: 100%;
                border-radius: 6px;
              }
            }
          }
        }

        .el-pagination {
          padding: 1.5% 10px;
          margin-top: 0;
        }
      }
      .menu-box {
        .no-data {
          font-size: 18px;
        }
      }
      .no-data {
        text-align: center;
        font-size: 28px;
        color: #aaa;
        line-height: 400px;
      }
    }
  }
}
.container-box {
  position: relative !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden !important;
}
.top_video {
  > video {
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 6px;
    position: absolute;
    top: 0;
    z-index: 2;
    left: 0;
    background: #000;
  }
  .video_action {
    position: absolute;
    top: 8px;
    right: 2px;
    z-index: 3;
    .ydfont {
      padding: 2px;
      margin-right: 2px;
      display: inline-block;
      width: 28px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      background: #ffffff;
      border-radius: 50%;
      color: #563279;
      font-size: 20px;
      font-weight: bold;
    }
  }
}
::v-deep .el-table .student_name {
  cursor: pointer;
}

.thussat_logo {
  width: 20%;
  position: absolute;
  z-index: 99;
}
</style>
