//监控人员相关
import 'aliyun-webrtc-sdk';
import {
    formatTime
} from "common/utils";
import {
    getStudentsAlic,
} from "r/admin/examvideo";

import configApp from "config/index";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
import localForage from "localforage";
// 数据监控
import {
    updateTestStatus,
    removeUser,
    monitorNumListener,
    recordChannel
} from "r/index/videoList";


import {
    isString
} from "element-ui/src/utils/types";
import {
    getAllExamData
} from "r/admin/group";

import {
    Form
} from "element-ui";
// 分科目

// 数据监控
import {
    getDataMonitor,
    selectSubject
} from "r/admin/examvideo";

const selectOptions = ['所有考生', '消息'];
const studentStartInfo = {
    channelId: '',
    subjectUuid: '',
    groupId: '',
    index: '',
    key: '',
    name: '',
    subjectId: '',
    zkzNum: '',
    '0': {
        is_join: 0,
        is_video: 0,
        is_call: 0,
        is_audio: 0,
        check_fps: 0,
        fps_times: 0,
    }
};
export const videoMixin = {
    data() {
        return {
            list: [],
            name: '',
            changeNameA: false,
            topText: '',
            menuLoading: false,
            menuText: '数据加载中',
            studentList: [],
            setTimeoutPageObj: null,
            canSetTimeoutPage: true,
            setTimeoutPageTime: 0, //自动翻页时间 单位分钟
            setTimeoutPageOptions: [{
                label: '翻页时间设置',
                options: [{
                    value: 0,
                    label: '关闭翻页'
                }, {
                    value: 1,
                    label: '一分钟'
                }, {
                    value: 2,
                    label: '两分钟'
                }, {
                    value: 5,
                    label: '五分钟'
                }, {
                    value: 10,
                    label: '十分钟'
                }, {
                    value: 15,
                    label: '一刻钟'
                }, {
                    value: 30,
                    label: '半小时'
                }]
            }],
            showStudentList: [],
            subscribeAudioStudentList: {},
            channelArr: [],
            pageSize: 9,
            pageNum: 1,
            recordPageNum: 0,
            recordPageSize: 15,
            videoSize: 0,
            showVideo: false,
            topLoading: false,
            userPageId: 0,
            userId: 0,
            subjectId: 0,
            subjectUuid: 0,
            examUuid: 0,
            messageUnCount: 0,
            top_video: false,
            topAliWebrtc: {},
            student: studentStartInfo,
            aliWebrtcArr: [],
            exam_subject_video_number: "考试名称+考试科目+监控组号", //标题
            total: 0, // 数据总条数
            totalPage: 1, // 总页数
            checkboxGroup: 1,
            chooseArray: selectOptions,
            all_call: false,
            loadingMessage: false,
            noMessage: false,
            stomp: {},
            socket: null,
            teacherId: 0,
            messageList: [],
            abnormalMessageList: [],
            userVideoList: ["front", "back"],
            userVideoListScreen: ["front", "back", "screen"],
            userVideoListOne: ["front"],
            hasScreenVideo: true,
            userVideoListTest: {
                "front": "前置",
                "back": "后置"
            },
            topKeyArr: {},
            dialogVisibleFu: false, // 控制发送消息弹框显示与隐藏
            dataInfo: {},
            userKey: '', // 当前发送消息的本地缓存key
            userType: '', // 正、反面监控  front-正面  back-反面
            all_send: 0, // // 发送消息 0-单个 1-全体 2-评委
            msgSingleObj: {}, // 发送的单个消息对象列表
            msgAllList: [], // 发送的全体消息列表
            aliWebrtc_tea: {}, //老师aliWebrtc
            isshowMe: false, //是否显示老师自己的摄像头
            imgwidth: 50, //老师自己
            imgheight: 50,

            optionRtc: {
                isReceiveOnly: configApp.rtc.canCameraPublish,
            },

            //调试按钮
            // testStatus: 0,
            queryTestStatus: {
                zkzNum: null,
                subjectUuid: null,
            },
            hasHorizontalTop: true,
            // 填码通道
            radioCode: 0,
            // 交卷通道
            radioPaper: 0,
            refreshTimeArr: [1, 1, 2, 2, 2, 4, 4, 4, 4, 8, 8, 8, 8, 8, 16, 16, 16, 16, 16, 16, 20], //重新连接的时间间隔，单位秒，最大的是默认是20s
            refreshSocketTimeout: 0, //自动重连的定时器
            refreshSocket: 0, //自动重连的定时器

            showStudentDialog: false,
            isNowPage: true,
            isChangeStatus: 0,

            examlistInfo: {
                phone: "",
                pageNum: 1,
                pageSize: 10,
                name: "",
                examId: "",
                status: "",
                token: "",
            },
            queryInfo: {
                pageNum: 1,
                pageSize: 10,
                status: "",
                examName: "",
            },
            teacherName: "", //监考人显示【0
            teacherPhone: "", //监考人显示【0
            showAside: false,
            examName: "",
            subjectName: "",
            loading: true,
            examId: "",
            searchList: [],
            examInfoList: [],
            subjectList: [],
            searchExamName: "",
            searchStudentName: "",
            isCollapse: false,
            searchInfo: {
                name: "",
            },
            userInfoDta: JSON.parse(window.localStorage.getItem("admin-userInfo")),
            showControl: 0, //视频监控与数据监控切换变量
            // 数据监控
            generalControl: {
                lackExamStatus: "",
                notFaceNum: "",
                notEnterNum: "",
                notHandPaperNum: "",
                notStartExamNum: "",
                notSubjectiveNum: "",
                notTestNum: "",
            },
            dataControlList: [],
            totalNum: 0,
            queryParamsData: {
                pageNum: 1,
                pageSize: 10,
                subjectUuid: null,
                smallSubjectUuid: null,
                teacherId: null,
                condition: null,
                monitorStatus: null,
                startExamStatus: null,
                testStatus: null,
                pictureStatus: null,
                handPaperStatus: null,
                lackExamStatus: null,
                finalEndTime: null,
                faceStatus: null,
            },
            dataCondition: '', //搜索考生
            timer: null, //定时器名称

            smallSubjectUuid: "", //select分科目
            selectSubjectLIst: [], //获取分科目List
            videoCondition: "", //搜索筛选video
            dialogTableVisible: false,
            dialogVisibleImg: false,
            // (查看图片上传的传参)
            zkzNums: "", //准考证号
            names: "", //
            subjectUuids: [],
            dialogVisibleError: false, //单个预警
            /* 补时 */
            timeDialogVisible: false, //补时
            timeForm: {
                name: "",
                zkzNums: "",

                titleSubjectName: "", //补时（时间）
            },
            timeRules: {
                code: [{
                    required: true,
                    message: "请输入补时码",
                    trigger: "blur",
                },
                    {
                        type: "number",
                        message: "补时码必须为数字值"
                    },
                ],
                titleSubjectName: [{
                    required: true,
                    message: "请输入补时时长（按分钟计算）",
                    trigger: "blur",
                },
                    {
                        type: "number",
                        message: "补时时长必须为数字值"
                    },
                ],
            },
            id: "", //补时间参数
            /* 标记异常 */
            errorDialogVisible: false,
            errorForm: {
                name: "",
                zkzNums: "",
                errorType: "",
                errorDescribe: "",
            },
            errorRules: {
                errorType: [{
                    required: true,
                    message: "请选择事件类型",
                    trigger: "blur",
                },],
            },
            errorOptions: [],
            //请求协助
            helpDialogVisible: false,
            helpForm: {
                name: "",
                zkzNums: "",
                helpType: "",
                helpDescribe: "",
            },
            helpRules: {
                helpType: [{
                    required: true,
                    message: "请选择事件类型",
                    trigger: "blur",
                },],
            },
            helpOptions: [],
            userInfo: window.localStorage.getItem("index-userInfo"),
            imagesList: [],
            errorImagesList: [],
            valuePicker: [], // 起止时间-时间选择器绑定值
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                teacherId: null,
                subjectUuid: null,
                zkzNum: null,
                startTime: null,
                finalEndTime: null,
                type: null,
            },
            queryParamsError: {
                pageNum: 1,
                pageSize: 10,
                teacherId: null,
                subjectUuid: null,
                zkzNum: null,
                startTime: null,
                finalEndTime: null,
                type: null,
            },
            imgTotal: 0,
            errorimgTotal: 0,
            // 预警类型列表
            typeList: [{
                value: 0,
                label: "没有人脸",
            },
                {
                    value: 1,
                    label: "人脸多于一个",
                },
                {
                    value: 2,
                    label: "戴耳机",
                },
                {
                    value: 3,
                    label: "打电话",
                },
            ],
            imgTimer: null, // 查看预警截图-定时器
            imgErrorTimer: null, // 查看预警截图-定时器
            fit: "cover",
            selectSubjectData: [], //分科目选择
            EnterNum: "", //进入监控人数
            notEnterNum: "", //未进入监控人数
            is_check: 0,
            missExamText: "", //标记缺考提示
            countJtTypesList: [], // 左侧监控列表-预警列表
            loadingJtType: false, // true-预警加载中
            noJtType: false, // 当前预数据状态 true-无数据
            noJtTypeUnCount: 0, // 当前预警人数
            JtTypeTimer: null, // 定时器刷新左侧监控列表-预警列表

            drawerShow: false, // 控制学生视频异常列表的显示与隐藏

            // 异常记录按钮
            // 异常记录tabs
            activeName: "first",

            checkErrorOpen: "",
            isPagePublisher: 1,
            dialogVisiblecheckError: false,
            checkerr: 0,

            errorLoading: false,
            ErrorEventList: [], //记录异常List
            ErrorEventTotal: 0,
            ErrorEventPageSize: 10,
            ErrorEventPageNum: 1,
            HelpEventList: [], //请求协助List
            HelpEventTotal: 0,
            HelpEventPageSize: 10,
            HelpEventPageNum: 1,
        };
    },
    created() {
        this.getTeacherExamsLists();
        this.examlistInfo.phone = this.userInfoDta.name;
        this.teacherId = localStorage.getItem('admin-userInfo-name');
        // this.cellMouseEnter();
    },
    mounted() {
        let aliWebrtc = new AliRtcEngine();
        /**
         * AliWebRTC isSupport检测
         */
        aliWebrtc.enableCamera = configApp.rtc.canCameraPublish;
        aliWebrtc.isSupport(this.optionRtc).then(re => {
            this.initSocket();
        }).catch(error => {
            this.$notify.error({
                title: '错误',
                message: error.message
            });
        })
    },
    methods: {
        showVideoModel(type, item) {
            let status = true;
            if (type == 'screen' && item.screen_active == 0) {
                status = false;
            }
            return status;
        },
        // 获取小科目List
        getSelectSubject() {
            this.queryParamsData.subjectUuid = this.subjectUuid;
            selectSubject(this.queryParamsData).then((res) => {
                if (res.data.code != 200) {
                    return this.$message.error(res.data.message);
                }
                this.selectSubjectLIst = res.data.data;
                // if (window.localStorage.getItem("admin_smallSubjectUuid")) {
                //     this.smallSubjectUuid = window.localStorage.getItem(
                //         "admin_smallSubjectUuid"
                //     );
                // } else {
                this.smallSubjectUuid = this.selectSubjectLIst[0].smallSubjectUuid;
                window.localStorage.setItem(
                    "admin_smallSubjectUuid",
                    this.smallSubjectUuid
                );
                // }
            });
        },
        changeSmallSubject(value) {
            window.localStorage.setItem("admin_smallSubjectUuid", value);
            this.smallSubjectUuid = value;
            this.getDataMonitor();
        },
        // 搜索视频
        searchStudent() {
            this.getList();
        },
        closeImgListDialog(val, type) {
            this.dialogVisibleImg = val;
            if (type) {
                this.timer && clearTimeout(this.timer);
            }
        },
        /* 数据监控  */
        // 搜索考生
        // 搜索考生
        searchStudentData() {
            this.getDataMonitor(1)
        },
        getDataMonitor(type) {
            this.timer && clearTimeout(this.timer);
            this.topLoading = true
            this.queryParamsData.subjectUuid = this.subjectUuid;
            this.queryParamsData.teacherId = this.teacherId;
            this.queryParamsData.smallSubjectUuid = window.localStorage.getItem(
                "admin_smallSubjectUuid"
            );
            if (this.dataCondition != "") {
                this.queryParamsData.condition = this.dataCondition;
            }
            if (type == 1) {
                this.queryParamsData.pageNum = 1;
            }
            getDataMonitor(this.queryParamsData).then((res) => {
                if (res.data.code != 200) {
                    return this.$message.error(res.data.message);
                }
                this.generalControl.lackExamStatus = res.data.data.lackExamStatus;
                this.generalControl.notFaceNum = res.data.data.notFaceNum;
                this.generalControl.notEnterNum = res.data.data.notEnterNum;
                this.generalControl.notHandPaperNum = res.data.data.notHandPaperNum;
                this.generalControl.notStartExamNum = res.data.data.notStartExamNum;
                this.generalControl.notSubjectiveNum = res.data.data.notSubjectiveNum;
                this.generalControl.notTestNum = res.data.data.notTestNum;
                this.dataControlList = res.data.data.detail.records;
                this.totalNum = res.data.data.detail.total;
                this.topLoading = false;
                this.startTimer();
            });
        },
        // 取第一位监考人
        cellMouseEnter(row, column, cell, event) {
            // console.log(row.teacherPhone.split(",")[1] === undefined)
            // console.log(row.teacherName.split(' '))
            // console.log(row.teacherName.split(' ').indexOf(','))
            console.log(row.teacherName)
            // console.log(row.teacherName.indexOf(",") != -1);

            if (column.label == "监考人") {
                if (
                    row.teacherName.indexOf(',') != -1
                ) {
                    this.teacherName = row.teacherName.split(",")[0];

                } else {
                    this.teacherName = row.teacherName
                }

            } else if (column.label == "监考人手机号") {
                if (
                    row.teacherPhone.indexOf(',') != -1
                ) {
                    this.teacherPhone = row.teacherPhone.split(",")[0];

                } else {
                    this.teacherPhone = row.teacherPhone
                }
            }
        },
        handleControl() {
            this.showControl = 1;
            // return;
            this.refreshBtn();
            // 定时刷新
            //this.getDataMonitor();
        },
        // 定时器（刷新
        startTimer() {
            this.timer && clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.getDataMonitor();
            }, 60000);
        },
        // 刷新按钮/*  */
        refreshBtn() {
            this.topLoading = true;
            this.getDataMonitor();
        },
        handleVideo() {
            this.showControl = 0;
            // 消除定时刷新
            this.timer && clearTimeout(this.timer);
        },

        filterChange(filters) {
            if (filters.faceStatus != undefined) {
                this.queryParamsData.faceStatus = filters.faceStatus[0];
            } else if (filters.monitorStatus != undefined) {
                this.queryParamsData.monitorStatus = filters.monitorStatus[0];
            } else if (filters.startExamStatus != undefined) {
                this.queryParamsData.startExamStatus = filters.startExamStatus[0];
            } else if (filters.testStatus != undefined) {
                this.queryParamsData.testStatus = filters.testStatus[0];
            } else if (filters.pictureStatus != undefined) {
                this.queryParamsData.pictureStatus = filters.pictureStatus[0];
            } else if (filters.handPaperStatus != undefined) {
                this.queryParamsData.handPaperStatus = filters.handPaperStatus[0];
            } else if (filters.lackExamStatus != undefined) {
                this.queryParamsData.lackExamStatus = filters.lackExamStatus[0];
            }
            this.topLoading = true;
            this.getDataMonitor();
        },

        handleDataSize(val) {
            this.queryParamsData.pageSize = val;
            this.topLoading = true;
            this.getDataMonitor();
        },
        handleDataCurrente(val) {
            this.queryParamsData.pageNum = val;
            this.topLoading = true;
            this.getDataMonitor();
        },

        isLoading() {
            setTimeout(() => {
                if (
                    this.subjectName != "" &&
                    this.examName != "" &&
                    this.teacherId != ""
                ) {
                    this.loading = false;
                }
            }, 300);
        },
        //获取老师监考科目列表
        async getTeacherExamsLists() {
            const data = await getAllExamData(this.searchInfo);
            if (data.status != 200) {
                this.$message({
                    message: res.message,
                    type: "error",
                });
            } else {
                this.examInfoList = data.data;

                this.subjectList = data.data;
                // if (this.examInfoList == data.data) {
                //   //当考试列表获取之后在获取科目列表
                //   for (let i = 0; i < this.examInfoList.length; i++) {
                //     this.examlistInfo.examId = this.examInfoList[i].examId;
                //     if (this.examlistInfo.examId === this.examInfoList[i].examId) {
                //       //获取考试列表之后，主动获取每场考试对应科目列表
                //       const { data: res } = await getAllteacherSubs(this.examlistInfo);
                //       console.log(res,"返回")
                //       if (res.code != 200) {
                //         this.$message({
                //           message: res.message,
                //           type: "error",
                //         });
                //         return;
                //       }
                //       if (res.code == 200) {
                //         console.log("监控科目列表", res);
                //         let sublist = [];
                //         let arr = [];
                //         sublist = res.data.teacherExamDto.examSubjects ? res.data.teacherExamDto.examSubjects: [] ;
                //         console.log(sublist, "kemuliebiao 22222");
                //         if (sublist.length != 0) {
                //             if (this.examInfoList[i].uuid === this.teacherPhone.examId) {
                //               // this.examName = this.examInfoList[i].name;
                //               console.log("yes  yes", this.examName);
                //               for (let j = 0; j < sublist.length; j++) {
                //                 sublist[j].listName =
                //                   this.examInfoList[i].name + "+" + sublist[j].subjectName;
                //                 arr.push(sublist[j]);
                //                 console.log(arr, "。。。。。。。");
                //               }
                //             }
                //           this.subjectList = arr;
                //           console.log(this.subjectList, "科目列表列表表表");
                //         }
                //       }
                //     }
                //   }
                // }
            }
        },
        collapseStatus() {
            this.showAside = !this.showAside;
        },
        //搜索
        getExamInfoList() {
            //this.examlistInfo.examName = this.searchExamName
            this.queryInfo.examName = this.searchExamName;
            // if(this.examlistInfo.examName === this.searchExamName && this.searchExamName != ''){
            //      this.getTeacherExamsLists()
            // }

            if (
                this.queryInfo.examName === this.searchExamName &&
                this.searchExamName != ""
            ) {
                this.getTeacherExamsLists();
            }

        },
        chooseChecked(examId, subjectName, index) {
            this.examlistInfo.examId = examId;
            this.showAside = !this.showAside;
            this.examName = this.examInfoList[index].name;
            this.subjectName = subjectName;
            this.subjectId = this.examInfoList[index].subjectId;
            this.examUuid = this.examInfoList[index].examId;
            this.subjectUuid = this.examInfoList[index].subjectUuid;
            this.getSelectSubject();
            this.pageNum = 1;
            this.getList();
        },
        // 填码通道
        async changeHandlerCode(value, item) {
            const confirmResult = await this.$confirm(`确认开启考生：${item.name} 的填码通道？`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                closeOnClickModal: false,
            }).catch((err) => err);

            if (confirmResult !== "confirm")
                return this.$message.info("您取消了");
            this.radioCode = value
            recordChannel({
                zkzNum: item.zkzNum,
                subjectUuid: this.subjectUuid,
                status: 0
            }).then(res => {
                if (res.data.code == 200) {
                    return this.$message.success("操作成功")
                }
            })
            // 局部刷新
            item.codeStatus = value
        },
        // 交卷通道
        async changeHandlerPaper(value, item) {
            const confirmResult = await this.$confirm(`确认开启考生：${item.name} 的交卷通道？`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                closeOnClickModal: false,
            }).catch((err) => err);

            if (confirmResult !== "confirm")
                return this.$message.info("您取消了");
            this.radioPaper = value
            recordChannel({
                zkzNum: item.zkzNum,
                subjectUuid: this.subjectUuid,
                status: 1
            }).then(res => {
                if (res.data.code == 200) {
                    return this.$message.success("操作成功")
                }
            })
            // 局部刷新
            item.handPaperStatus = value
        },
        // 关闭监控（踢人）
        closeMonitor(item) {
            let channelId = item.channelId
            let userId = item.zkzNum + '_front,' + item.zkzNum + '_back'
            removeUser(this.examUuid, this.subjectUuid, channelId, userId).then((res) => {
                if (res.data.code != 200) {
                    return this.$message.error(res.data.message);
                }
                this.$message.success('操作成功');
            });
        },
        showStudentDialogShow(zkzNum, isChangeStatus = 1) {
            isChangeStatus && this.changeStatus(zkzNum);
            this.canSetTimeoutPage = false;
            this.setTimeoutPage();
            let userId = this.subjectUuid + "_" + zkzNum + "_" + "front";
            let student = this.getPagePublisher(userId);
            if (student) {
                this.isPagePublisher = 1;
            } else {
                this.isPagePublisher = 0;
                student = this.getPublisher(userId);
            }
            if (student) {
                if (!this.isNowPage || this.isPagePublisher === 0) {
                    this.showStudent = student;
                    this.showStudentDialog = true;
                }
                this.is_check = 1;
                student.is_check = 1;
                this.isChangeStatus = isChangeStatus;
                this.studentList[student["key"]] = student;
                if (this.isPagePublisher == 1 && this.showStudentList[student["newKey"]]) {
                    this.showStudentList[student["newKey"]] = student;
                }
                if (this.isPagePublisher == 0 || !this.isNowPage) {
                    let fix = this.subjectUuid + "_" + student.zkzNum;
                    //重新订阅流
                    for (let v = 0; v < this.userVideoList.length; v++) {
                        let userId1 = fix + "_" + this.userVideoList[v];
                        if (student[userId1].is_join == 1) {
                            this.$nextTick(() => {
                                this.subscribeUser(userId1, student['screen_active'], student, 1, false);
                            });
                        }
                    }
                }
            }
        },
        hideStudentDialogShow(zkzNum) {
            this.$nextTick(() => {
                this.canSetTimeoutPage = true;
                this.setTimeoutPage();
                let userId = this.subjectUuid + "_" + zkzNum + "_" + "front";
                let student = this.getPublisher(userId);
                if (student) {
                    this.is_check = 0;
                    this.showStudent = null;
                    this.showStudentDialog = false;
                    this.isChangeStatus = 0;
                    student.is_check = 0;
                    let key = student["key"];
                    this.studentList[key] = student;
                    if (
                        this.isPagePublisher == 1 &&
                        this.showStudentList[student["newKey"]]
                    ) {
                        this.showStudentList[student["newKey"]] = student;
                    }
                    if (!this.isNowPage || this.isPagePublisher == 0) {
                        let fix = this.subjectUuid + "_" + zkzNum;
                        //重新订阅流
                        for (let v = 0; v < this.userVideoList.length; v++) {
                            let userId1 = fix + "_" + this.userVideoList[v];
                            if (student[userId1].is_join == 1) {
                                let is_call = student[userId1].is_call;
                                let is_audio = student[userId1].is_audio;
                                this.$nextTick(() => {
                                    if (is_audio) {
                                        this.unSubscribeUser(userId1, student);
                                    }
                                    if (is_call) {
                                        this.callOneStudent(userId1, student, 2);
                                    }
                                });
                            }
                        }
                        this.isPagePublisher = 1;
                        this.$forceUpdate();
                    }
                }
            });
        },
        // 置顶数组
        Totop(userId) {
            let student = this.getPublisher(userId);
            if (student) {
                let key = student['key'];
                let topKey = this.studentList[0]['topKey'];
                this.studentList[key].topKey = ++topKey;
                if (this.pageNum > 1) {
                    this.is_check = 0;
                    this.studentList[key].is_check = 0;
                    this.isPagePublisher = 0;
                    this.showStudent = null;
                    this.showStudentDialog = false;
                    this.isChangeStatus = 0;
                    this.pageNum = 1;
                }
                this.$nextTick(() => {
                    this.studentList.sort((a, b) => {
                        return b.topKey - a.topKey;
                    });
                    this.topKeyArr[student.zkzNum] = topKey;
                    localForage.setItem("topKeyArr", this.topKeyArr);
                    this.reSortStudentList(this.studentList);
                    this.changeStudentVideoList();
                    this.$forceUpdate();
                })

            }
        },

        reSortStudentList(studentList) {
            let key = 0;
            let list = [];
            for (let i = 0; i < studentList.length; i++) {
                let temp = studentList[i];
                temp['key'] = key;
                key = ++key;
                list.push(temp);
            }
            this.studentList = list;
        },


        //获取当前监控组的所有学生
        async getList() {
            this.topLoading = true;
            this.menuLoading = true;
            this.topText = '数据加载中';
            let start = this.pageNum;
            let end = this.pageSize;
            this.changeStudentVideoList();
            let condition = this.videoCondition;
            let {
                data
            } = await getStudentsAlic(this.teacherId, this.subjectId, this.examUuid, condition, start, end);
            this.topLoading = false;
            this.menuLoading = false;
            this.topText = '';

            if (data.data && data.data.total > 0) {
                this.list = JSON.parse(data.data.list);
                this.total = data.data.total;
                if (this.list.length > 0) {
                    this.videoSize = parseInt(this.list[0].videoSize);
                    if (this.hasScreenVideo) {
                        this.videoSize = this.videoSize + 1;
                        this.userVideoList = this.userVideoListScreen;
                    }
                    if (this.videoSize === 1) {
                        this.userVideoList = this.userVideoListOne;
                    }
                }
                this.totalPage = Math.ceil(this.total / this.pageSize);
                this.createRtc();
            }
        },
        //初始化aliwebrtc对象
        createRtc() {
            this.studentList = [];
            let studentList = [];
            let list = this.list;
            let key = 0;
            for (let i = 0; i < list.length; i++) {
                let aliWebrtc = new AliRtcEngine();
                aliWebrtc.configLocalCameraPublish = configApp.rtc.canCameraPublish;
                aliWebrtc.enableAudioVolumeIndicator = false;
                let config = JSON.parse(list[i].token);
                let channelId = config.channelId;
                this.aliWebrtcArr[channelId] = aliWebrtc;
                this.channelArr[channelId] = {
                    is_publish: 0,
                    config: config
                };
                let data = list[i][channelId];
                // if(data.length > 1){
                //     this.$notify.error({
                //         title: '错误',
                //         message: "频道人数超过上限(ID:"+channelId+")"
                //     });
                //     return false;
                // }
                let tempData = [];
                let userVideoList = this.userVideoList;
                for (let j = 0; j < data.length; j++) {
                    let temp = data[j];
                    let fix = this.subjectUuid + '_' + temp.zkzNum;
                    for (let v = 0; v < userVideoList.length; v++) {
                        temp[fix + '_' + userVideoList[v]] = {
                            is_join: 0, //是否入会
                            is_video: 0, //是否有订阅视频流
                            is_call: 0, //是否和学生通话
                            is_audio: 0, //是否听学生声音
                            check_fps: 0,
                            fps_times: 0,
                        };
                    }
                    temp['screen_active'] = 0;
                    temp['key'] = key;
                    temp['topKey'] = 0;
                    temp['abnormal'] = 0;
                    temp['is_check'] = 0;
                    tempData.push(temp);
                    key = ++key;
                }
                studentList = studentList.concat(tempData);
                //加入房间
                this.joinRoom(channelId, config);
                //监听事件
                try {
                    this.initRtc(channelId);
                } catch (e) {

                }
            }
            this.studentList = studentList;
            this.channelArr = this.removePropertyOfNull(this.channelArr);
            this.aliWebrtcArr = this.removePropertyOfNull(this.aliWebrtcArr);
            this.searchList = this.studentList;
            this.showStudentList = this.studentList;
            let times = localStorage.getItem("setTimeoutPageTime", this.setTimeoutPageTime + "");
            if (times) {
                this.setTimeoutPageTime = parseInt(times);
            }
            this.setTimeoutPage();
        },
        //自动翻页
        setTimeoutPage() {
            if (this.showControl != 0) {
                this.canSetTimeoutPage = false;
            }
            this.setTimeoutPageObj && clearTimeout(this.setTimeoutPageObj);
            this.setTimeoutPageObj = null;

            if (!this.canSetTimeoutPage) {
                return false;
            }
            localStorage.setItem("setTimeoutPageTime", this.setTimeoutPageTime + "");
            if (this.setTimeoutPageTime > 0) {
                this.setTimeoutPageObj = setTimeout(() => {
                    this.setTimeoutPageObj && clearTimeout(this.setTimeoutPageObj);
                    let newNum = this.pageNum;
                    newNum = ++newNum;
                    if (newNum > this.totalPage) {
                        newNum = 1;
                    }
                    this.handleCurrentChange(newNum);
                }, this.setTimeoutPageTime * 60000);
            }
        },
        //aliwebrtc的事件监听
        initRtc(channelId) {
            let aliWebrtc = this.aliWebrtcArr[channelId];
            /**
             * remote用户加入房间 onJoin
             * 更新在线用户列表
             */
            aliWebrtc.on("onJoin", (publisher) => {
                let userId = publisher.userId;
                if (userId) {
                    this.updateUserJoinList(userId); //学生入会更新状态
                }
            });
            /**
             * remote流发布事件 onPublish
             * 将该用户新增到推流列表
             * 若该用户已存在推流列表，则进行状态更新
             */
            aliWebrtc.on("onPublisher", (publisher) => {
                console.log("onPublisher", publisher)
                let userId = publisher.userId;
                if (userId) {
                    let streamConfigs = publisher.streamConfigs;
                    let screen_active = 0;
                    streamConfigs.forEach((item) => {
                        if (item.label == 'sophon_video_screen_share' &&
                            item.state == 'active') {
                            screen_active = 1;
                        }
                    })
                    this.subscribeUser(userId, screen_active); //订阅学生流
                    this.checkHasCallUser(userId); //判断和那些学生正在通话恢复通话
                    // this.monitorNumListener(userId);//向服务端发送在线日志
                }
            });

            /**
             * remote流结束发布事件 onUnPublisher
             * 推流列表删除该用户
             * 移除用户视图
             * 初始化订阅状态
             */
            aliWebrtc.on("onUnPublisher", (publisher) => {
                let userId = publisher.userId;
                if (userId) {
                    this.unSubscribePublisher(userId); //更新学生订阅的状态
                    this.unPublisherErrMessage(userId); //更新学生监控异常的错误日志
                }
            });

            /**
             * 被服务器踢出或者频道关闭时回调 onBye
             */
            aliWebrtc.on("onBye", (message) => {
                //1:被服务器踢出
                //2:频道关闭
                //3:同一个ID在其他端登录,被服务器踢出
                let msg;
                switch (message.code) {
                    case 1:
                        msg = "被服务器踢出";
                        // 清除本地的 token
                        //window.localStorage.clear();
                        // 使用编程式导航跳转到登录页面
                        //this.$router.push("/login");
                        break;
                    case 2:
                        msg = "频道关闭";
                        break;
                    case 3:
                        msg = "同一个ID在其他端登录,被服务器踢出";
                        break;
                    default:
                        msg = "onBye";
                }
                console.error("onBye", msg);
            });

            /**
             *  错误信息
             */
            aliWebrtc.on("onError", (error) => {
                //this.getList();
                let msg = error && error.message ? error.message : error;
                if (msg && msg.indexOf("no session") > -1) {
                    msg = "请重新登录：" + msg;
                }
                if (error.errorCode === 10011 || error.errorCode === 10012) {
                    msg = error.errorCode === 10011 ? "屏幕共享被禁止" : "屏幕共享已取消";
                    // setTimeout(() => {
                    //   $("#screenPublish").removeAttr("checked");
                    //   getPublishState("danger");
                    // }, 2000);
                }

                if (error.code == 15) {
                    msg = "没有开启H5兼容";
                }
                if (error.type === "publish") {
                    // 提示用户网络状态不佳
                    this.publishAudio(channelId);
                }
                if (error.type === "subscribe") {
                    //先记录当前用户的订阅状态
                    //let subInfo = this.getSubscribeInfo(index,error.userId);
                    //取消订阅状态
                    let userId = error.userId;
                    if (userId) {
                        let student = this.getPublisher(userId);
                        if (student && student[userId].is_video) {
                            let type = 1;
                            if (this.userId === userId) {
                                type = 2;
                            }
                            this.subscribeUser(userId, student['screen_active'], student, type, !!student[userId].is_audio);
                        }
                    }
                }
                // this.joinRoom(channelId);
                console.log('onError', error)
            });

            /**
             * 检测到用户离开频道
             * 更新用户列表
             * 移除用户视图
             */
            aliWebrtc.on("onLeave", (publisher) => {
                let userId = publisher.userId;
                this.delSubscribe(userId);
            })

            aliWebrtc.on("onMedia", (data) => {
                let userId = data.userId;
                let student = this.getPublisher(userId);
                if (student && student[data.userId].is_video) {
                    if (data.data.camera && student[userId].check_fps) {
                        //判断当前视频流的fps
                        if (data.data.camera.fps === 0 || data.data.bytesReceivedPerSecond === 0) {
                            let type = 1;
                            if (this.userId === userId) {
                                type = 2;
                            }
                            this.incrementFpsTimes(userId, student);
                            if (student[userId].fps_times > 60) {
                                this.subscribeUser(userId, student['screen_active'], student, type, !!student[data.userId].is_audio);
                            }
                        } else {
                            this.studentList[student['key']][userId].fps_times = 0;
                            if (this.showStudentList[student['newKey']]) {
                                this.showStudentList[student['newKey']][userId].fps_times = 0;
                            }
                        }
                    }
                }
            });
        },
        incrementFpsTimes(userId, student) {
            if (!student) {
                student = this.getPublisher(userId);
            }
            if (!student) {
                return false;
            }
            let fps_times = student[userId].fps_times + 1;
            this.studentList[student['key']][userId].fps_times = fps_times;
            if (this.showStudentList[student['newKey']]) {
                this.showStudentList[student['newKey']][userId].fps_times = fps_times;
            }
        },
        //取消订阅状态
        delSubscribe(userId) {
            let student = this.getPublisher(userId);
            if (student) {
                let key = student['key'];
                student[userId].is_join = 0;
                student[userId].is_video = 0;
                student[userId].is_call = 0;
                student[userId].is_audio = 0;
                student[userId].check_fps = 0;
                student[userId].fps_times = 0;
                this.studentList[key] = student;
                if (this.showStudentList[student['newKey']]) {
                    this.showStudentList[student['newKey']] = student;
                }
                this.$forceUpdate();
            }
        },
        //清除对象的空对象
        removePropertyOfNull(obj) {
            let _newPar = {};
            for (let key in obj) {
                //如果对象属性的值不为空，就保存该属性（这里我做了限制，如果属性的值为0，保存该属性。如果属性的值全部是空格，属于为空。
                if ((obj[key] === 0 || obj[key] === false || obj[key]) && obj[key].toString().replace(/(^\s*)|(\s*$)/g, '') !== '') {
                    _newPar[key] = obj[key];
                }
            }
            return _newPar;
        },
        checkUserId(userId) {
            if (!userId) {
                return false;
            }
            let info = {};
            let userArr = userId.split('_');
            if (userArr.length === 0) {
                return false;
            }
            //学生
            if (userArr.length === 3) {
                info.type = 1;
                let videoType = userArr[2];
                info.videoType = videoType;
                let index = this.userVideoList.indexOf(videoType);
                if (index > -1) {
                    let userVideoList = JSON.parse(JSON.stringify(this.userVideoList));
                    delete userVideoList[index];
                    userVideoList = userVideoList.sort();
                    info.videoToType = userVideoList[0] ? userVideoList[0] : this.userVideoList[index];
                    info.videoToUserId = userArr[0] + "_" + userArr[1] + "_" + info.videoToType;
                }
            }
            //监控老师
            if (userArr.length === 2) {
                info.type = 2;
            }
            info.userId = userId;
            return info;
        },
        unPublisherErrMessage(userId) {
            let userInfo = this.checkUserId(userId);
            if (userInfo.type === 1) {
                this.abnormalMessageList.push(userInfo);
            }
            //console.log(this.abnormalMessageList);
        },
        // 调试状态改变
        testStatusChange(item, index) {
            // return
            this.$confirm('此操作将标记报到检查状态, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.queryTestStatus.subjectUuid = this.subjectUuid
                this.queryTestStatus.zkzNum = item.zkzNum

                updateTestStatus(this.queryTestStatus).then((res) => {
                    if (res.data.code != 200) {
                        return this.$message.error(res.data.message);
                    }

                    this.showStudentList[index]["testStatus"] = 1
                    this.$message.success(res.data.message);
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            })
        },

        // 打开发送消息弹框
        msgShow(type, data, userType) {
            let userKey = this.examUuid + "_" + this.subjectUuid + "_" + this.teacherId;
            if (type == 0) {
                // 全体
                this.all_send = 1;
            } else if (type == 1) {
                // 单个
                this.all_send = 0;
                this.dataInfo = data;
                userKey += '_' + this.dataInfo.zkzNum + '_' + userType;
                this.canSetTimeoutPage = false;
                this.setTimeoutPage();
            }
            this.userKey = userKey;
            this.userType = userType;
            this.dialogVisibleFu = true;
        },
        // 关闭弹框
        closeDialog(value, type, msg, msgType = null) {
            this.dialogVisibleFu = value;
            this.canSetTimeoutPage = true;
            this.setTimeoutPage();
            if (type) {
                this.sendAllStudentMessage(msg, msgType);
            }
        },
        // //发送给所有学生
        sendAllStudentMessage(msg, msgType) {
            let message = {};
            let userId = "";
            let msgInfo = {};
            message.msg = msg;
            msgInfo.msg = msg;
            if (this.all_send==1) {
                // 全体
                if (msgType) {
                    // 语音
                    message.type = 4;
                    message.audio = msgType;
                } else {
                    message.type = 2;
                }
                message = JSON.stringify(message);
                this.sendMessage('/pubChat', message);
                msgInfo.time = formatTime(new Date());
                this.msgAllList.push(msgInfo);
            } else {
                // 单个
                console.log(msgType)
                if (msgType) {
                    // 语音
                    message.type = 4;
                    message.audio = msgType;
                } else {
                    message.type = 3;
                }
                userId = this.subjectUuid + '_' + this.dataInfo.zkzNum + '_' + this.userType;
                message = JSON.stringify(message);
                console.log(message)
                this.sendMessage('/pubChat', message, [userId]);
                msgInfo.time = formatTime(new Date());
                if (this.msgSingleObj[this.dataInfo.zkzNum]) {
                    this.msgSingleObj[this.dataInfo.zkzNum].push(msgInfo);
                } else {
                    this.msgSingleObj[this.dataInfo.zkzNum] = [];
                    this.msgSingleObj[this.dataInfo.zkzNum].push(msgInfo);
                }
            }
            let dataMessage = {
                "message": msg,
                "time": formatTime(new Date()),
            }
            localForage.getItem(this.userKey).then((list) => {
                if (list) {
                    list.push(dataMessage);
                } else {
                    list = [dataMessage];
                }
                localForage.setItem(this.userKey, list).then(() => {
                });
            });
        },

        //加载更多的学生举手记录
        async loadMessage() {
            ++this.recordPageNum;
            await this.getChatRecord();
        },
        /**
         * 和所有学生通话
         * @param call_type 0正常订阅模式 2-取消订阅所有学生
         */
        callAllStudent(call_type = 0) {
            if (call_type === 2) {
                this.all_call = false;
            } else {
                this.all_call = !this.all_call;
                this.canSetTimeoutPage = !this.all_call;
                this.setTimeoutPage();
            }
            let is_call = this.all_call ? 1 : 0;
            let studentList = this.studentList;
            let channelArr = {};
            this.subscribeAudioStudentList = {};
            for (let i = 0; i < studentList.length; i++) {
                let student = studentList[i];
                let userId = this.subjectUuid + '_' + student.zkzNum;
                let userVideo = this.checkStudentVideo(userId, student, false);
                if (!userVideo) {
                    continue;
                }
                userId = this.subjectUuid + '_' + student.zkzNum + '_' + userVideo;
                // if (student[userId].is_call == is_call) {
                //     continue;
                // }
                if (!channelArr[student['channelId']]) {
                    channelArr[student['channelId']] = [];
                    channelArr[student['channelId']][0] = userId;
                } else {
                    channelArr[student['channelId']].push(userId);
                }
                let aliWebrtc = this.aliWebrtcArr[student['channelId']];
                aliWebrtc.muteLocalCamera(this.all_call);
                this.teacherPublishUpStudentCall(userId, student, is_call, false);
            }
            if (channelArr) {
                for (const channelId in channelArr) {
                    if (this.channelArr[channelId].is_publish === 1) {
                        this.sendCallAllSocket(channelId, is_call, channelArr[channelId]);
                    } else {
                        this.publishAudio(channelId, !is_call, (res) => {
                            if (res) {
                                this.sendCallAllSocket(channelId, is_call, channelArr[channelId]);
                            } else {
                                this.$notify.error({
                                    title: '错误',
                                    message: '你的声音发布失败，请刷新重新重试'
                                });
                            }
                        });
                    }
                }
            }
        },
        /**
         * 向所有学生发送通知
         * @param channelId
         * @param type
         * @param receiver
         */
        sendCallAllSocket(channelId, type = 0, receiver = '') {
            let message = {
                channelId: channelId,
                teacherId: this.teacherId,
            };
            message.type = type;
            message = JSON.stringify(message);
            this.sendMessage('/pubChat', message, receiver);
        },
        //和单个学生通话 开启或关闭和学生建立连接
        callOneStudent(userId, student, type = 0) {
            if (!student) {
                student = this.getPublisher(userId);
            }
            let is_call = student[userId].is_call;
            if (type === 1 && is_call == 1) {
                return false;
            }
            if (type !== 2) {
                is_call = is_call ? 0 : 1;
            } else {
                if (is_call === 0) {
                    return false;
                }
                is_call = 0;
            }
            let aliWebrtc = this.aliWebrtcArr[student.channelId];
            aliWebrtc.muteLocalCamera(!is_call);
            this.canSetTimeoutPage = !is_call;
            this.setTimeoutPage();
            if (this.channelArr[student.channelId].is_publish === 1) {
                if (is_call) {
                    this.subscribeAudioStudentList[userId] = 1;
                } else {
                    if (this.subscribeAudioStudentList[userId]) {
                        delete this.subscribeAudioStudentList[userId];
                    }
                }
                this.aliWebrtcArr = this.removePropertyOfNull(this.aliWebrtcArr);
                this.teacherPublishUpStudentCall(userId, student, is_call);
            } else {
                this.publishAudio(student.channelId, !is_call, (res) => {
                    if (res) {
                        if (is_call) {
                            this.subscribeAudioStudentList[userId] = 1;
                        } else {
                            if (this.subscribeAudioStudentList[userId]) {
                                delete this.subscribeAudioStudentList[userId];
                            }
                        }
                        this.aliWebrtcArr = this.removePropertyOfNull(this.aliWebrtcArr);
                        this.teacherPublishUpStudentCall(userId, student, is_call);
                    } else {
                        this.$notify.error({
                            title: '错误',
                            message: '你的声音发布失败，请刷新重新重试'
                        });
                    }
                })
            }
            // let teacherId = this.teacherId+"_"+student.channelId
            // let teacherInfo = aliWebrtc.getUserInfo(teacherId);

        },
        //初始化 webSocket
        initSocket() {
            if (this.refreshSocketTimeout) {
                return true;
            }
            if (typeof (WebSocket) == "undefined") {
                this.$message({
                    showClose: true,
                    message: '您的浏览器不支持WebSocket',
                    type: 'error'
                });
            } else {
                let socket;
                if (!this.socket) {
                    socket = new SockJS(configApp.socket.base_url, null, {
                        timeout: configApp.socket.timeout
                    });
                    this.socket = socket;
                } else {
                    socket = this.socket;
                }
                let stomp = Stomp.over(socket);
                stomp.debug = null;
                this.stomp = stomp;
                // 每隔30秒做一次心跳检测
                stomp.heartbeat.outgoing = configApp.socket.outgoing;
                // 客户端不接收服务器的心跳检测
                stomp.heartbeat.incoming = configApp.socket.incoming;
                const user = {
                    'username': this.teacherId,
                    'token': localStorage.getItem("admin-token"),
                    'type': 't'
                };
                try {
                    //连接
                    stomp.connect(user, (frame) => {

                        //订阅广播
                        // stomp.subscribe("topic/chatRoom", function (res) {
                        //     console.log("top/chatRoom");
                        //     console.log(res.body);
                        // });
                        this.refreshSocket = 0;
                        this.refreshSocketTimeout && clearTimeout(this.refreshSocketTimeout);
                        //用户模式
                        stomp.subscribe("/user/" + this.teacherId + "/subChat", (res) => {
                            let data = JSON.parse(res.body);
                            if (data.code == 200) {
                                let message = JSON.parse(data.data.message);
                                message.isRead = 0;
                                message.id = data.data.messageId;
                                let messageType = message.type;
                                switch (messageType) {
                                    case 1:
                                        this.messageUnCount = ++this.messageUnCount;
                                        this.messageList.unshift(message);
                                        this.noMessage = false;
                                        break;
                                    case 2:
                                        let userId = data.data.user.username;
                                        // console.log(user_info);
                                        break;
                                }

                                // console.log(this.messageUnCount, "消息条数")
                            }
                        });
                    }, () => {


                        this.refreshSocket = ++this.refreshSocket;
                        let refreshTimeArr = this.refreshTimeArr;
                        let time = refreshTimeArr[this.refreshSocket - 1];
                        time = time ? time : refreshTimeArr[refreshTimeArr.length - 1];
                        this.refreshSocketTimeout = setTimeout(async () => {
                            this.refreshSocketTimeout && clearTimeout(this.refreshSocketTimeout);
                            this.refreshSocketTimeout = null;
                            this.socket = null;
                            this.initSocket();
                        }, time * 1000);
                    });
                } catch (e) {
                }
            }
        },
        checkStudentVideo(userid, student = null, is_alert = true) {
            if (!student) {
                student = this.getPublisher(userid);
            }
            if (!student || !student.name) {
                is_alert && this.$notify.error({
                    title: '错误',
                    message: '视频未开启连接'
                });
                return false;
            }
            let is_join = 0;
            let video = '';
            let userVideoList = this.userVideoList;
            for (let i = 0; i < userVideoList.length; i++) {
                let userVideo = userVideoList[i];
                if (student[this.subjectUuid + '_' + student.zkzNum + '_' + userVideo].is_join == 1) {
                    is_join = 1;
                    video = userVideo;
                    break;
                }
            }
            if (is_join == 0) {
                is_alert && this.$notify.error({
                    title: '错误',
                    message: student.name + '视频未开启连接'
                });
                return is_join;
            }
            return video;
        },
        //处理 学生通话 查看按钮事件
        showVideoSocket(messageKey, channelId, userid) {
            let message = this.messageList[messageKey];
            let student = this.getPublisher(userid);
            //检查是否有视频在线
            let hasStudentVideo = this.checkStudentVideo(userid, student);
            if (!hasStudentVideo) {
                return false;
            }
            userid = this.subjectUuid + '_' + student.zkzNum + '_' + hasStudentVideo;
            if (student) {
                this.showVideoDo(userid, student);
                this.messageList[messageKey].isRead = 1;
                this.messageUnCount = --this.messageUnCount;
                if (this.messageUnCount < 0) {
                    this.messageUnCount = 0;
                }
                signMessageReadable(message.id);
            } else {
                this.$notify.error({
                    title: '错误',
                    message: '视频未开启连接'
                });
            }
        },
        //处理 学生通话 处理按钮事件
        showVideoAndCall(messageKey, userid) {
            let message = this.messageList[messageKey];
            let student = this.getPublisher(userid);
            let hasStudentVideo = this.checkStudentVideo(userid, student);
            if (!hasStudentVideo) {
                return false;
            }
            userid = this.subjectUuid + '_' + student.zkzNum + '_' + hasStudentVideo;
            if (student) {
                this.showVideoDo(userid, student, true);
                this.callOneStudent(userid, student, 1);
                signMessageReadable(message.id);
                this.messageList[messageKey].isRead = 1;
                this.messageUnCount = --this.messageUnCount;
                if (this.messageUnCount < 0) {
                    this.messageUnCount = 0;
                }
            } else {
                this.$notify.error({
                    title: '错误',
                    message: '视频未开启连接'
                });
            }
        },
        //发送Socket 消息
        sendMessage(cmd, message, receiver = '') {
            let data = {
                "message": message
            };
            if (receiver) {
                data.receiver = receiver;
            }
            data.subjectUuid = this.subjectUuid;
            data = JSON.stringify(data);
            this.stomp.send(cmd, {}, data);
        },
        //侧边栏视频放大事件
        showVideoDo(userId, student, has_subscribeAudio = false) {
            let video = this.$refs['video_' + userId] ? this.$refs['video_' + userId][0] : false;
            let index = student['index'];
            let key = student['key'];
            //当前视频在当前分页中
            this.showVideo = false;
            if (video) {
                this.userId = 0;
                this.student = studentStartInfo;
                this.showTopVideo(userId);
                if (has_subscribeAudio) {
                    this.subscribeAudio(userId, student, has_subscribeAudio);
                }
            } else {
                //判断当前视频是否在该频道
                this.userId = userId;
                this.student = this.studentList[key];
                this.topLoading = true;
                this.top_video = true;
                this.topText = '视频加载中';
                this.$nextTick(() => {
                    this.subscribeUser(userId, student['screen_active'], student, 2, has_subscribeAudio);
                    this.topLoading = false;
                })
            }
        },
        //视频缩小事件
        hideTopVideo() {
            if (window.document.exitFullscreen) {
                window.document.exitFullscreen().then((r) => {

                }).catch((err) => {
                    this.top_video = true;
                    this.showVideo = true;
                });
            } else if (window.document.mozCancelFullScreen) {
                window.document.mozCancelFullScreen();
            } else if (window.document.webkitCancelFullScreen) {
                window.document.webkitCancelFullScreen();
            } else if (window.document.msExitFullscreen) {
                window.document.msExitFullscreen();
            }

        },
        // 控制每页显示几条数据
        handleSizeChange(newSize) {
            this.pageSize = newSize;
            this.setTimeoutPage();
            this.getList();
        },
        // 控制显示第几页
        handleCurrentChange(newNum) {
            this.pageNum = newNum;
            this.getList();
        },
        //页面改动重新订阅学生的音视频
        changeStudentVideoList() {
            //取消订阅当前页面学生的视频流
            if (this.studentList) {
                let list = this.studentList;
                for (let i = 0; i < list.length; i++) {
                    let student = list[i];
                    this.aliWebrtcArr[student.channelId].leaveChannel().then((res) => {
                    }, (error) => {
                    });
                }
                this.studentList = [];
            }
        },
        unSubscribeUser(userId, student = false) {
            if (!student) {
                student = this.getPublisher(userId);
            }
            if (student && student[userId].is_video) {
                let aliWebrtc = this.aliWebrtcArr[student.channelId];
                aliWebrtc.configRemoteCameraTrack(userId, false, false);
                aliWebrtc.muteRemoteAudioPlaying(userId, true);
                aliWebrtc.configRemoteAudio(userId, false);
                aliWebrtc.setAudioVolume(userId, 1);
                aliWebrtc.subscribe(userId).then(res => {
                }).catch((error) => {
                    console.log(error, "userId:" + userId, "channelId:" + student['channelId'], "unSubscribeUser");
                })
                student[userId].is_video = 0;
                student[userId].is_audio = 0;
                student[userId].check_fps = 0;
                this.studentList[student['key']][userId] = student[userId];
                if (this.showStudentList[student['newKey']]) {
                    this.showStudentList[student['newKey']][userId] = student[userId];
                }
            }
        },
        //视频列表 视频放大事件
        showTopVideo(userId, type = 1) {
            this.userId = userId;
            let video;
            let studnet = null;
            if (type === 1) {
                this.userPageId = userId;
                this.userId = 0;
                this.student = studentStartInfo;
                video = this.$refs['video_' + userId] ? this.$refs['video_' + userId][0].parentNode : false;
                studnet = this.getPublisher(userId);
            } else {
                this.topLoading = false;
                this.topText = '';
                video = this.$refs['top_video_' + userId] ? this.$refs['top_video_' + userId].parentNode : false;
            }
            if (this.showVideo) {
                this.imgwidth = 50;
                if (window.document.exitFullscreen) {
                    window.document.exitFullscreen();

                } else if (window.document.mozCancelFullScreen) {
                    window.document.mozCancelFullScreen();

                } else if (window.document.webkitCancelFullScreen) {
                    window.document.webkitCancelFullScreen();

                } else if (window.document.msExitFullscreen) {
                    window.document.msExitFullscreen();
                }

                // if (!!document.fullscreen ||
                //   document.mozFullScreen ||
                //   document.webkitIsFullScreen ||
                //   document.webkitFullScreen ||
                //   document.msFullScreen
                // ) {
                //   this.imgwidth = 50
                // }
            } else {
                this.imgwidth = 250
                try {
                    if (video) {
                        if (video.requestFullscreen) {
                            video.requestFullscreen();
                            // this.imgwidth = 50
                        } else if (video.msRequestFullscreen) {
                            video.msRequestFullscreen();
                            // this.imgwidth = 50
                        } else if (video.mozRequestFullScreen) {
                            video.mozRequestFullScreen();
                            // this.imgwidth = 50
                        } else if (video.webkitRequestFullscreen) {
                            video.webkitRequestFullscreen();
                            // this.imgwidth = 50
                        }

                    } else {
                        this.imgwidth = 50
                    }

                } catch (e) {

                }
            }
        },
        //监听视频放大缩小改变的事件
        fullScreenChangeHandler() {
            this.showVideo = !this.showVideo;
            this.canSetTimeoutPage = !this.showVideo;
            this.setTimeoutPage();

            if (this.userId !== 0) {
                //非本页面学生 放大视频和缩小视频监控
                if (this.showVideo === false) {
                    //断流 关闭声音和通话
                    let student = this.student;
                    let userId = this.userId;
                    this.unSubscribeUser(userId, student);
                    // if (student[userId].is_call) {
                    //   this.callOneStudent(userId, student);
                    // }
                    this.top_video = false;
                    this.showVideo = false;
                    this.teacherPublishUpStudentCall(userId, student, 0);
                } else {
                    this.top_video = true;
                }
            } else {
                let userId = this.userPageId;
                this.top_video = false;
                this.student = studentStartInfo;
                this.userId = 0;
                if (this.showVideo === false) {
                    this.subscribeAudio(userId, false, false, 2);
                    this.callOneStudent(userId, false, 2);
                }
            }
        },
        //学生加入频道 更新学生信息
        updateUserJoinList(userId) {
            let student = this.getPublisher(userId);
            if (student && student[userId].is_join == 0) {
                student[userId].is_join = 1;
                student[userId].is_video = 0;
                student[userId].is_audio = 0;
                student[userId].check_fps = 0;
                student[userId].fps_times = 0;
                let key = student['key'];
                this.studentList[key][userId] = student[userId];
                if (this.showStudentList[student['newKey']]) {
                    this.showStudentList[student['newKey']][userId] = student[userId];
                }
                this.$forceUpdate();
            }
        },
        //取消订阅音频状态
        unSubscribeAudio(userId, student = false) {
            if (!student) {
                student = this.getPublisher(userId);
            }
            let examUuid = localStorage.getItem('index-examId');
            // removeUser(this.subjectUuid,examUuid,student.channelId,userId);
            if (student && student[userId].is_video) {
                let aliWebrtc = this.aliWebrtcArr[student.channelId];
                aliWebrtc.muteRemoteAudioPlaying(userId, true);
                student[userId].is_audio = 0;
                this.studentList[student['key']][userId] = student[userId];
                if (this.showStudentList[student['newKey']]) {
                    this.showStudentList[student['newKey']][userId] = student[userId];
                }
            }
        },
        //订阅用户的音频
        subscribeAudio(userId, student = false, has_subscribeAudio = false, type = 1) {
            if (!student) {
                student = this.getPublisher(userId);
            }
            let key = student['key'];
            let is_audio = this.studentList[key][userId].is_audio;
            if (has_subscribeAudio && is_audio == 1) {
                return true;
            }
            if (type === 1) {
                is_audio = is_audio ? 0 : 1;
            } else {
                if (is_audio === 0) {
                    return true;
                }
                is_audio = 0;
            }
            let aliWebrtc = this.aliWebrtcArr[student.channelId];
            aliWebrtc.muteRemoteAudioPlaying(userId, !is_audio);
            aliWebrtc.setAudioVolume(userId, 1);
            this.studentList[key][userId].is_audio = is_audio;
            if (this.showStudentList[student['newKey']]) {
                this.showStudentList[student['newKey']][userId].is_audio = is_audio;
            }
            this.$forceUpdate();
        },
        /**
         * 加入房间
         * 触发：输入房间号、单击加入房间按钮
         * 更新页面信息
         * 默认开启预览
         * 获取鉴权信息
         * 加入房间
         * 本地默认自动推视频流（视频流 + 音频流）
         * 发布本地流
         */
        joinRoom(channelId, config = null) {
            let aliWebrtc = this.aliWebrtcArr[channelId];
            let userName = '监控老师_' + this.teacherId;
            //2. 获取频道鉴权令牌参数 为了防止被盗用建议该方法在服务端获取
            let authInfo = {};
            if (!config) {
                config = this.channelArr[channelId].config
            }
            authInfo.appid = config.appid;
            authInfo.userid = config.userid;
            authInfo.timestamp = config.timestamp;
            authInfo.nonce = config.nonce;
            authInfo.token = config.token;
            authInfo.gslb = config.gslb;
            authInfo.channel = config.channelId;
            aliWebrtc.joinChannel(authInfo, userName).then(() => {
                this.publishAudio(channelId);
            }).catch((error) => {
                // console.log("[监控加入房间失败]" + error.message);
            })
        },


        publishAudio(channelId, mute = true, cb = null) {
            let aa = setTimeout(() => {
                aa && clearTimeout(aa);
                let aliWebrtc = this.aliWebrtcArr[channelId];
                aliWebrtc.configLocalAudioPublish = true;
                aliWebrtc.configLocalCameraPublish = false;
                aliWebrtc.configLocalScreenPublish = false;
                aliWebrtc.enableCamera = false; //设置是否允许使用摄像头
                aliWebrtc.publish().then((res) => {
                    this.channelArr[channelId].is_publish = 1;
                    cb && cb(true);
                }).catch((error) => {
                    this.channelArr[channelId].is_publish = 0;
                    cb && cb(false);
                });
            }, 100)

        },
        /**
         * 老师发布或关闭音频流 更新学生状态和发送socket
         * @param userId 学生id
         * @param student 学生信息
         * @param is_call 是否请求通话 0-关闭通话 1-请求通话
         * @param send_socket 是否发送socket
         */
        teacherPublishUpStudentCall(userId, student, is_call, send_socket = true) {
            if (!student) {
                student = this.getPublisher(userId);
            }
            let channelId = student['channelId'];
            let aliWebrtc = this.aliWebrtcArr[channelId];
            aliWebrtc.muteLocalCamera(!is_call);
            let key = student['key'];
            this.studentList[key][userId].is_call = is_call;
            if (this.showStudentList[student['newKey']]) {
                this.showStudentList[student['newKey']][userId].is_call = is_call;
            }
            if (send_socket) {
                let message = {
                    channelId: channelId,
                    teacherId: this.teacherId,
                };
                message.type = is_call;
                message = JSON.stringify(message);
                this.sendMessage('/pubChat', message, [userId]);
            }
            this.$forceUpdate();
        },

        //频道老师是否订阅
        /**
         * 频道老师是否订阅
         * @param userId 用户id
         * @param student 用户信息
         * @param type 类型1-当前页面的学生 2-非当前页面的学生
         * @param has_subscribeAudio
         * @returns {boolean}
         */
        subscribeUser(userId, screen_active = false, student = false, type = 1, has_subscribeAudio = false) {
            if (!student) {
                student = this.getPagePublisher(userId);
            }
            if (!student) {
                return false;
            }
            this.subscribeScreenVideo(userId, screen_active, student, type, has_subscribeAudio)
            if (student[userId].is_video) {
                return false;
            }
            let video;
            if (type === 1) {
                this.userId = 0;
                this.student = studentStartInfo;
                video = this.$refs['video_' + userId] ? this.$refs['video_' + userId][0] : false;
            } else {
                video = this.$refs['top_video_' + userId] ? this.$refs['top_video_' + userId] : false;
            }
            if (video) {
                let aliWebrtc = this.aliWebrtcArr[student.channelId];
                //前置摄像头
                aliWebrtc.configRemoteCameraTrack(userId, true, true);
                if (this.hasScreenVideo) {
                    aliWebrtc.configRemoteScreenTrack(userId, true);
                }
                aliWebrtc.muteRemoteAudioPlaying(userId, !has_subscribeAudio);
                aliWebrtc.configRemoteAudio(userId, true);
                //aliWebrtc.muteAllRemoteAudioPlaying(!has_subscribeAudio);
                aliWebrtc.setAudioVolume(userId, 1);
                this.studentList[student['key']][userId].check_fps = 0;
                this.studentList[student['key']][userId].fps_times = 0;
                if (this.showStudentList[student['newKey']]) {
                    this.showStudentList[student['newKey']][userId].fps_times = 0;
                }
                aliWebrtc.subscribe(userId).then(res => {
                    aliWebrtc.setDisplayRemoteVideo(
                        userId, // 用户ID
                        video, // html中用于显示stream对象的video元素
                        1 // 1表示摄像头流（大流和小流），2表示屏幕分享流
                    );
                    let top = video.parentNode;
                    if (type === 2) {
                        this.showTopVideo(userId, 2);
                    }
                    if (top) {
                        top.addEventListener('fullscreenchange', this.fullScreenChangeHandler);
                        top.addEventListener('mozfullscreenchange', this.fullScreenChangeHandler);
                        top.addEventListener('webkitfullscreenchange', this.fullScreenChangeHandler);
                        top.addEventListener('msfullscreenchange', this.fullScreenChangeHandler);
                    }
                    student[userId].is_join = 1;
                    student[userId].is_video = 1;
                    student[userId].fps_times = 0;

                    if (has_subscribeAudio) {
                        student[userId].is_audio = 1;
                    }
                    this.studentList[student['key']][userId] = student[userId];
                    if (this.showStudentList[student['newKey']]) {
                        this.showStudentList[student['newKey']][userId] = student[userId];
                    }
                    let setTimeoutIndex = setTimeout(() => {
                        setTimeoutIndex = clearTimeout(setTimeoutIndex);
                        student[userId].check_fps = 1;
                        this.studentList[student['key']][userId] = student[userId];
                        if (this.showStudentList[student['newKey']]) {
                            this.showStudentList[student['newKey']][userId] = student[userId];
                        }
                    }, 20000)
                    this.$forceUpdate();
                }).catch((error) => {
                    this.updateUserJoinListFail(userId)
                    console.log(error, "userId:" + userId, "channelId:" + student['channelId'], "subscribeUser");
                })
            }
        },
        // 订阅屏幕流
        subscribeScreenVideo(userId, screen_active, student = false, type = 1, has_subscribeAudio = false) {
            let arr = userId.split('_');
            if (!arr[2] || (arr[2] && arr[2] != "back")) {
                return false;
            }
            let newUserId = arr[0] + "_" + arr[1] + "_screen";
            this.studentList[student['key']]['screen_active'] = screen_active;
            if (this.showStudentList[student['newKey']]) {
                this.showStudentList[student['newKey']].screen_active = screen_active;
            }
            if (screen_active != 1) {
                this.updateUserJoinListFail(newUserId)
                return false;
            }
            if (student[newUserId].is_video) {
                return false;
            }
            let video;
            if (type === 1) {
                this.userId = 0;
                this.student = studentStartInfo;
                video = this.$refs['video_' + newUserId] ? this.$refs['video_' + newUserId][0] : false;
            } else {
                video = this.$refs['top_video_' + newUserId] ? this.$refs['top_video_' + newUserId] : false;
            }
            if (video) {
                let aliWebrtc = this.aliWebrtcArr[student.channelId];
                //前置摄像头
                aliWebrtc.configRemoteScreenTrack(userId, true);
                aliWebrtc.muteRemoteAudioPlaying(userId, !has_subscribeAudio);
                aliWebrtc.configRemoteAudio(userId, true);
                //aliWebrtc.muteAllRemoteAudioPlaying(!has_subscribeAudio);
                aliWebrtc.setAudioVolume(userId, 1);
                this.studentList[student['key']][newUserId].check_fps = 0;
                this.studentList[student['key']][newUserId].fps_times = 0;
                if (this.showStudentList[student['newKey']]) {
                    this.showStudentList[student['newKey']][newUserId].fps_times = 0;
                }
                aliWebrtc.subscribe(userId).then(res => {
                    aliWebrtc.setDisplayRemoteVideo(
                        userId, // 用户ID
                        video, // html中用于显示stream对象的video元素
                        2 // 1表示摄像头流（大流和小流），2表示屏幕分享流
                    );
                    let top = video.parentNode;
                    if (type === 2) {
                        this.showTopVideo(newUserId, 2);
                    }
                    if (top) {
                        top.addEventListener('fullscreenchange', this.fullScreenChangeHandler);
                        top.addEventListener('mozfullscreenchange', this.fullScreenChangeHandler);
                        top.addEventListener('webkitfullscreenchange', this.fullScreenChangeHandler);
                        top.addEventListener('msfullscreenchange', this.fullScreenChangeHandler);
                    }
                    student[newUserId].is_join = 1;
                    student[newUserId].is_video = 1;
                    student[newUserId].fps_times = 0;

                    if (has_subscribeAudio) {
                        student[newUserId].is_audio = 1;
                    }
                    this.studentList[student['key']][newUserId] = student[newUserId];
                    if (this.showStudentList[student['newKey']]) {
                        this.showStudentList[student['newKey']][newUserId] = student[newUserId];
                    }
                    let setTimeoutIndex = setTimeout(() => {
                        setTimeoutIndex = clearTimeout(setTimeoutIndex);
                        student[userId].check_fps = 1;
                        this.studentList[student['key']][newUserId] = student[newUserId];
                        if (this.showStudentList[student['newKey']]) {
                            this.showStudentList[student['newKey']][newUserId] = student[newUserId];
                        }
                    }, 20000)
                    this.$forceUpdate();
                }).catch((error) => {
                    this.updateUserJoinListFail(newUserId)
                    console.log(error, "userId:" + userId, "channelId:" + student['channelId'], "subscribeUser");
                })
            }
        },
        //学生加入频道-失败 更新学生信息
        updateUserJoinListFail(userId) {
            let student = this.getPublisher(userId);
            if (student && student[userId].is_join == 1) {
                student[userId].is_join = 0;
                student[userId].is_join = 0;
                student[userId].is_video = 0;
                student[userId].is_audio = 0;
                student[userId].check_fps = 0;
                student[userId].fps_times = 0;
                let key = student['key'];
                this.studentList[key][userId] = student[userId];
                if (this.showStudentList[student['newKey']]) {
                    this.showStudentList[student['newKey']][userId] = student[userId];
                }
                this.$forceUpdate();
            }
        },
        //判断和那些学生正在通话恢复通话
        checkHasCallUser(userId) {
            if (this.all_call === false) {
                if (!this.subscribeAudioStudentList[userId]) {
                    return false;
                }
            }
            let student = this.getPublisher(userId);
            if (!student) {
                return false;
            }
            this.callOneStudent(userId, student, 1);
        },
        updateUserAbnormal(userId, student = null) {
            if (!student) {
                student = this.getPublisher(userId);
            }
            if (student) {
                let abnormal = ++student.abnormal;
                student.abnormal = abnormal > this.videoSize ? this.videoSize : abnormal;
                this.studentList[student['key']] = student;
                if (this.showStudentList[student['newKey']]) {
                    this.showStudentList[student['newKey']] = student;
                }
                this.$forceUpdate();
            }
        },
        //获取该监控组下某个学生的信息
        getPublisher(userId) {
            let studentList = this.studentList;
            let student = false;
            let zkz = userId.split('_')[1];
            for (let i = 0; i < studentList.length; i++) {
                if (studentList[i].zkzNum === zkz) {
                    return studentList[i];
                }
            }
            return student;
        },
        //获取当前分页下某个学生的信息
        getPagePublisher(userId) {
            let studentList = this.studentList;
            let student = false;
            let zkz = userId.split('_')[1];
            for (let i = 0; i < studentList.length; i++) {
                if (studentList[i].zkzNum === zkz) {
                    return studentList[i];
                }
            }
            return student;
        },
        unSubscribeScreenVideo(userId) {
            let arr = userId.split('_');
            if (!arr[2] || (arr[2] && arr[2] != "back")) {
                return false;
            }
            let newUserId = arr[0] + "_" + arr[1] + "_screen";
            this.unSubscribePublisher(newUserId);
        },
        //用户掉线取消订阅
        unSubscribePublisher(userId) {
            this.unSubscribeScreenVideo(userId);
            let student = this.getPublisher(userId);
            if (student) {
                student[userId].is_join = 1;
                student[userId].is_video = 0;
                student[userId].is_call = 0;
                student[userId].is_audio = 0;
                student[userId].check_fps = 0;
                student[userId].fps_times = 0;
                let abnormal = --student.abnormal;
                student.abnormal = abnormal < 0 ? 0 : abnormal;
                this.studentList[student['key']] = student;
                if (this.showStudentList[student['newKey']]) {
                    this.showStudentList[student['newKey']] = student;
                }
                this.$forceUpdate();
            }
        },


        // 打开异常draw
        handleAbnormal() {
            this.drawerShow = true;
        },
        // 关闭异常draw
        handleDrawClose() {
            this.drawerShow = false;
        },
        noJtTypeButton(val) {
            if (val == 3) {
                // 预警按钮
                this.JtTypeTimer && clearInterval(this.JtTypeTimer);
                this.countJtTypeList();
                this.JtTypeTimer = setInterval(() => {
                    this.countJtTypeList();
                }, 60000);
            }
        },
        // 标记查看预警
        changeStatus(zkz) {
            let obj = {
                zkzNum: zkz,
                subjectUuid: this.subjectUuid,
            };
            changeStatus(obj)
                .then((res) => {
                    let resData = res.data;
                    if (resData.code != 200) {
                        return this.$message.error(resData.message);
                    }
                    this.countJtTypesList.map((item) => {
                        if (item.zkzNum == zkz && item.status == 1) {
                            item["status"] = 0;
                            this.noJtTypeUnCount--;
                        }
                    });
                    // this.toErrorList(zkz);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        // 预警状态
        countJtTypeStatus(val) {
            if (val == 0) {
                return "未检测到人脸";
            } else if (val == 1) {
                return "人脸多于1个";
            } else if (val == 2) {
                return "带耳机";
            } else if (val == 3) {
                return "打电话";
            }
        },
        // 预警列表
        countJtTypeList() {
            this.loadingJtType = true;
            this.noJtType = false;
            let obj = {
                teacherId: this.teacherId,
                subjectUuid: this.subjectUuid,
            };
            countJtType(obj)
                .then((res) => {
                    let resData = res.data;
                    if (resData.code != 200) {
                        this.loadingJtType = false;
                        this.noJtType = true;
                        return this.$message.error(resData.message);
                    }
                    this.loadingJtType = false;
                    this.countJtTypesList = resData.data;
                    if (this.countJtTypesList.length <= 0) {
                        this.noJtType = true;
                    } else {
                        this.noJtType = false;
                        let noJtTypeUnCount = 0;
                        this.countJtTypesList.map((item) => {
                            if (item.status == 1) {
                                noJtTypeUnCount++;
                            }
                            if (item.type) {
                                item["type"] = item.type.split(",");
                            } else {
                                item["type"] = [];
                            }
                        });
                        this.noJtTypeUnCount = noJtTypeUnCount;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        /* 数据监控  */
        // 搜索考生姓名
        getStaffPageLists() {
            this.topLoading = true;

            this.getDataMonitor(1);
        },

        // 标记缺考
        signMissexam(row) {
            if (row.lackExamStatus == 0) {
                this.missExamText = "是否确认该考生缺考?";
            } else {
                this.missExamText = "是否取消该考生缺考?";
            }
            const h = this.$createElement;
            this.$confirm("提示", {
                message: h("div", null, this.missExamText),
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    let obj = {};
                    obj.id = row.id;
                    if (row.lackExamStatus == 1) {
                        obj.lackExamStatus = 0;
                    } else {
                        obj.lackExamStatus = 1;
                    }
                    updateStudent(obj).then((res) => {
                        if (res.data.code != 200) {
                            return this.$message.error(res.data.message);
                        }
                        this.getDataMonitor();

                        this.$message.success(res.data.message);
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "取消操作",
                    });
                });
        },

        // 补时
        addTimeDialog(row) {
            this.timeDialogVisible = true;
            this.id = row.id;
            this.timeForm.zkzNums = row.zkzNum;
            this.timeForm.name = row.name;
        },
        handleAddTime() {
            let obj = {};
            obj.id = this.id;
            obj.code = this.timeForm.code;
            obj.additionalTime = this.timeForm.titleSubjectName;

            AddTime(obj).then((res) => {
                if (res.data.code != 200) {
                    return this.$message.error(res.data.message);
                }
                this.timeForm = {};
                this.$message.success("补时完成");
                this.timeDialogVisible = false;
                this.getDataMonitor();
            });
        },
        handleClose(done) {
            this.$confirm("确认关闭？")
                .then((_) => {
                    done();
                })
                .catch((_) => {
                });
        },
        /* 异常记录  */
        createTimeFormatter(row, column, cellValue, index) {
            const time = row.createTime;
            return formatTime(time);
        },
        handleRecordError() {
            this.isNowPage = false;
            this.showControl = 2;
            this.changeStudentVideoList(false);
            this.handleErrorEventList();
        },
        // tabs
        handleClick(tab, event) {
            if (tab.paneName === "first") {
                this.handleErrorEventList();
            } else {
                this.handleHelpEventList();
            }
        },
        handleErrorEventSize(val) {
            this.ErrorEventPageSize = val;
            this.errorLoading = true;
            this.handleErrorEventList();
        },
        handleErrorEventCurrent(val) {
            this.ErrorEventPageNum = val;
            this.errorLoading = true;
            this.handleErrorEventList();
        },
        //记录异常List
        handleErrorEventList() {
            let obj = {};
            obj.type = 1;
            obj.teacherId = this.teacherId;
            obj.pageNum = this.ErrorEventPageNum;
            obj.pageSize = this.ErrorEventPageSize;
            getErrorEvent(obj).then((res) => {
                if (res.data.code != 200) {
                    return this.$message.error(res.data.message);
                }
                this.ErrorEventList = res.data.data.records;
                this.ErrorEventTotal = res.data.data.total;
                this.errorLoading = false;
            });
        },
        handleHelpEventSize(val) {
            this.HelpEventPageSize = val;
            this.errorLoading = true;
            this.handleHelpEventList();
        },
        handleHelpEventCurrent(val) {
            this.HelpEventPageNum = val;
            this.errorLoading = true;
            this.handleHelpEventList();
        },
        //请求协助List
        handleHelpEventList() {
            let obj = {};
            obj.type = 0;
            obj.teacherId = this.teacherId;
            obj.pageNum = this.HelpEventPageNum;
            obj.pageSize = this.HelpEventPageSize;
            getErrorEvent(obj).then((res) => {
                if (res.data.code != 200) {
                    return this.$message.error(res.data.message);
                }
                this.HelpEventList = res.data.data.records;
                this.HelpEventTotal = res.data.data.total;
                this.errorLoading = false;
            });
        },

        // 记录异常
        errorDialog(row) {
            this.errorDialogVisible = true;
            this.errorForm.zkzNums = row.zkzNum;
            this.errorForm.name = row.name;
        },
        getErrorOption(event, type) {
            if (type == 1) {
                this.getCategoryList(type);
            } else {
                this.getCategoryList(type);
            }
        },
        getCategoryList(type) {
            getCategory({
                type: type
            }).then((res) => {
                // 请求协助
                if (type == 0) {
                    this.helpOptions = res.data.data;
                } else {
                    this.errorOptions = res.data.data;
                }
            });
        },

        handleError() {
            let obj = {};
            obj.zkzNum = this.errorForm.zkzNums;
            obj.teacherId = this.teacherId;
            obj.subjectUuid = this.subjectUuid;
            obj.eventCategoryId = this.errorForm.errorType;
            obj.eventProblemDescribe = this.errorForm.errorDescribe;
            saveErrorEvent(obj).then((res) => {
                if (res.data.code != 200) {
                    return this.$message.error(res.data.message);
                }
                this.$message.success(res.data.message);
                this.errorDialogVisible = false;
                this.errorForm = {};
                this.timer && clearTimeout(this.timer);
                // this.getDataMonitor();
                // this.startTimer();
            });
        },
        errorhandleClose(done) {
            this.$confirm("确认关闭？")
                .then((_) => {
                    done();
                })
                .catch((_) => {
                });
        },
        // 请求协助
        helpDialog(row) {
            this.helpDialogVisible = true;
            this.helpForm.zkzNums = row.zkzNum;
            this.helpForm.name = row.name;
        },
        handleHelp() {
            let obj = {};
            obj.zkzNum = this.helpForm.zkzNums;
            obj.teacherId = this.teacherId;
            obj.subjectUuid = this.subjectUuid;
            obj.eventCategoryId = this.helpForm.helpType;
            obj.eventProblemDescribe = this.helpForm.helpDescribe;
            saveErrorEvent(obj).then((res) => {
                if (res.data.code != 200) {
                    return this.$message.error(res.data.message);
                }
                this.$message.success(res.data.message);
                this.helpDialogVisible = false;
                this.helpForm = {};
                this.timer && clearTimeout(this.timer);
            });
        },
        helphandleClose(done) {
            this.$confirm("确认关闭？")
                .then((_) => {
                    done();
                })
                .catch((_) => {
                });
        },
        /* 查看单个预警截图 */
        // 打开预警截图弹框
        toErrorList(zkzNum) {
            this.dialogVisibleError = true;
            this.zkzNums = zkzNum;
        },
        // 查看预警截图-获取数据
        async openErroImgDialog() {
            window.clearInterval(this.imgErrorTimer);
            this.imgErrorTimer = window.setInterval(() => {
                this.openErroImgDialog();
            }, 2000);
            this.queryParamsError.teacherId = this.teacherId;
            this.queryParamsError.subjectUuid = this.subjectUuid;
            this.queryParamsError.zkzNum = this.zkzNums;
            const {
                data: res
            } = await warnImages(this.queryParamsError);
            if (res.code !== 200) return this.$message.error(res.message);
            this.errorImagesList = res.data.records;
            this.errorimgTotal = res.data.total;
        },

        // 关闭预警截图弹框
        closeErrorImgDialog() {
            window.clearInterval(this.imgErrorTimer);
        },

        handleErrImgSize(val) {
            this.queryParamsError.pageSize = val;
            this.openErroImgDialog();
        },
        handleErrImgCurrente(val) {
            this.queryParamsError.pageNum = val;
            this.openErroImgDialog();
        },

        /* 查看图片-按钮 */
        toImgList(item, num, name) {
            this.dialogVisibleImg = true;
            this.zkzNums = num;
            this.names = name;
            this.subjectUuids = this.subjectUuid;
        },
        handleImgSizeChange(val) {
            this.queryParams.pageSize = val;
            this.openImgDialog();
        },
        handleImgCurrentChange(val) {
            this.queryParams.pageNum = val;
            this.openImgDialog();
        },
        queryHandle() {
            this.queryParams.pageNum = 1;
            this.openImgDialog();
        },
        resetHandle() {
            this.valuePicker = [];
            this.queryParams.startTime = null;
            this.queryParams.finalEndTime = null;
            this.$refs["queryForm"].resetFields();
            this.queryHandle();
        },
        typeFormat(row) {
            let arr = row.type.split(",");
            let strArr = [];
            arr.forEach((item) => {
                this.typeList.forEach((item2) => {
                    if (item2.value == item) {
                        strArr.push(item2.label);
                    }
                });
            });
            return strArr;
        },
        changePicker(val) {
            if (val && val.length > 0) {
                this.queryParams.startTime = val[0];
                this.queryParams.finalEndTime = val[1];
            } else {
                this.queryParams.startTime = null;
                this.queryParams.finalEndTime = null;
            }
        },
        // 关闭预警截图弹框
        closeImgDialog() {
            window.clearInterval(this.imgTimer);
        },
        // 查看预警截图-获取数据
        async openImgDialog() {
            window.clearInterval(this.imgTimer);
            this.imgTimer = window.setInterval(() => {
                this.openImgDialog();
            }, 2000);
            this.queryParams.teacherId = this.teacherId;
            this.queryParams.subjectUuid = this.subjectUuid;
            const {
                data: res
            } = await warnImages(this.queryParams);
            if (res.code !== 200) return this.$message.error(res.message);
            this.imagesList = res.data.records;
            this.imgTotal = res.data.total;
        },
        // 开启或者关闭截图
        async startOrCloseVideoByTeacher(status, message) {
            const confirmResult = await this.$confirm(message, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                closeOnClickModal: false,
            }).catch((err) => err);

            if (confirmResult !== "confirm")
                return this.$message.info("您取消了操作");

            const {
                data: res
            } = await startOrCloseVideo({
                teacherId: this.teacherId,
                subjectUuid: this.subjectUuid,
                status,
            });
            if (res.code !== 200) return this.$message.error(res.message);
            this.$message.success(res.message);
        },
        openWarnJt() {
            this.dialogTableVisible = true;
        },
        // 给填码通道和胶卷通道赋值
        showChannel(item) {
            this.radioCode = item.codeStatus;
            this.radioPaper = item.handPaperStatus;
        },
    },
    activated() {

    },

    destroyed() {
        for (const index in this.aliWebrtcArr) {
            this.aliWebrtcArr[index].leaveChannel().then(() => {
                //console.log('离开房间成功');
                this.aliWebrtcArr[index] = null;
            }, (error) => {
                // console.log(error.message);
            });
        }
        this.socket && this.socket.close();
    }

};
